import { Remove } from "@material-ui/icons";
import { useEffect, useState } from "react";
import OffLabelIcon from "../../assets/icons/off_label.svg";

const MOSwitch = ({
  name="",
  isOnStatus = false,
  id = 1,
  setStatus = (isOn) => {},
  isParentControl = false,
}) => {
  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => {
    setIsOn(!isOn);
    setStatus(!isOn);
  };
  useEffect(() => {
    setIsOn(isOnStatus);
    // setStatus(isOnStatus);
  }, [isOnStatus]);

  // if isParentControl yes
  useEffect(() => {
    if (isParentControl) {
      setIsOn(isOnStatus);
    }
  }, [isOn]);
  return (
    <div className="switch-container">
      <input
        type="checkbox"
        className="switch-checkbox"
        id={`switch-${id}`}
        checked={isOn}
        onChange={toggleSwitch}
      />
      <label
        className={`switch-label ${
          isOn ? "switch-label-on" : "switch-label-off"
        }`}
        htmlFor={`switch-${id}`}
      >
        {isOn ? (
          <>
            <span>
              <Remove
                style={{
                  rotate: "90deg",
                  color: "#fff",
                  width: "13px",
                  height: "10px",
                  marginLeft: "3px",
                }}
              />
            </span>
          </>
        ) : (
          <>
            <span className="switch-off-label-text">
              <img
                src={OffLabelIcon}
                style={{
                  marginBottom: "2px",
                }}
              />
            </span>
          </>
        )}
        <span
          className={`switch-button ${isOn ? "switch-on" : "switch-off"}`}
        />
      </label>
    </div>
  );
};

export default MOSwitch;
