// import React from "react";
// import DoughnutChart from "../../../../components/commons/DoughnutChart";
// import HorizontalMultipleBarChart from "../../../../components/commons/HorizontalMultipleBarChart";

// const RequestAnalysis = () => {
//   return (
//     <div>
//       <h1>RequestAnalysis</h1>
//       <div className="bg-white">
//         {/* <DoughnutChart />
//         <HorizontalMultipleBarChart /> */}
//       </div>
//     </div>
//   );
// };

// export default RequestAnalysis;

import React, { useCallback, useEffect, useState } from "react";
import PriceIcon from "../../../../assets/icons/dashboard_price_icon.svg";
import CompletedTransfer from "../../../../assets/icons/dashbaord_transfer_icon.svg";
import PendingTransfer from "../../../../assets/icons/dashboard_setting_icon.svg";
import CanceledTransfer from "../../../../assets/icons/dashbaord_cancel_icon.svg";
import {
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowUpward,
  ExpandMore,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  RemoveRedEyeOutlined,
  Sort,
} from "@material-ui/icons";
import Select from "react-select";
import { useNavigate, useParams } from "react-router";
import SearchingInput from "../../../../components/commons/SearchingInput";
import EditIcon from "../../../../assets/icons/lucide_edit.svg";
import BarChart from "../../../../components/commons/BarChart";
import LineChartV2 from "../../../../components/commons/LineChartV2";
import PieChart from "../../../../components/commons/PieChart";
import { formatCurrency } from "../../../../helpers/getSymbolFromCurrency";
import DateRangeField from "../../../../components/commons/DateRangeField";
import HorizontalIcon from "../../../../assets/icons/horizontalIcon.png";
import SettingIcon from "../../../../assets/icons/menu_icon_settings.svg";
import ManageIcon from "../../../../assets/icons/enable_menu_icon.svg";
import DropDown from "../../../../components/commons/DropDown";
import mobioApi from "../../../../services";
import { connect } from "react-redux";
import { parseJsonString } from "../../../../helpers/parseJson";
import Pagination from "../../../../components/commons/Pagination";
import { CircularProgress } from "@material-ui/core";
const dummyFetchDataItem = async (page, itemsPerPage) => {
  const allItems = Array.from({ length: 100 }, (_, i) => `Item #${i + 1}`);
  const start = page * itemsPerPage;
  const end = start + itemsPerPage;

  return {
    items: allItems.slice(start, end),
    total: allItems.length,
  };
};
const SERVICE_LEVEL_ICONS = {
  LUXURY:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5045.png",
  LARGE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  EXECUTIVE:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5046.png",
  STANDARD:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5044.png",
  MINI_BUS:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  EXECUTIVE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  ELECTRIC:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5047.png",
  CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  ELECTRIC_LUXURY:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273+(1).png",
  ELECTRIC_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  ELECTRIC_EXE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  ELECTRIC_LARGE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  EXE_CARRIER: "",
  LARGE_CARRIER: "",
  PEOPLE_CARRIER: "",
};
const dateRanges = [
  { value: "01-01-2024-07-01-2024", label: "01-01-2024 to 07-01-2024" },
  { value: "08-01-2024-14-01-2024", label: "08-01-2024 to 14-01-2024" },
  { value: "15-01-2024-21-01-2024", label: "15-01-2024 to 21-01-2024" },
  { value: "22-01-2024-28-01-2024", label: "22-01-2024 to 28-01-2024" },
  { value: "29-01-2024-04-02-2024", label: "29-01-2024 to 04-02-2024" },
  { value: "05-02-2024-11-02-2024", label: "05-02-2024 to 11-02-2024" },
  { value: "12-02-2024-18-02-2024", label: "12-02-2024 to 18-02-2024" },
  { value: "19-02-2024-25-02-2024", label: "19-02-2024 to 25-02-2024" },
];

// Define routes options
const routes = [
  { value: "PAK", label: "PAK" },
  { value: "UK", label: "UK" },
  { value: "AMS", label: "AMS" },
  { value: "NYC", label: "NYC" },
  { value: "LON", label: "LON" },
  { value: "DXB", label: "DXB" },
  { value: "BKK", label: "BKK" },
  { value: "SIN", label: "SIN" },
];
const RequestAnalysis = ({ token }) => {
  const navigate = useNavigate();

  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const [anchorEl, setAnchorEl] = useState("");
  const [requestedData, setRequestedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState(null);
  const weekFormatOptionLabel = (name, { context }) => (
    <>
      {context === "value" && (
        <p className="m-0 ">
          <span className="font-600 ">This week </span>
          <span className="font-12 font-400"> {`(From ${name.label})`}</span>
        </p>
      )}

      {context === "menu" && (
        <>
          <p className="font-14 mb-0">{name.label}</p>
        </>
      )}
    </>
  );
  const routeFormatOptionLabel = (name, { context }) => (
    <>
      {context === "value" && (
        <p className="m-0 ">
          <span className="font-400 font-400 ">Most Searched Routes: </span>
          <span className="font-14 font-600"> {name.label}</span>
        </p>
      )}

      {context === "menu" && (
        <>
          <p className="font-14 mb-0">{name.label}</p>
        </>
      )}
    </>
  );
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #ced4da" : "1px solid #ced4da",
      boxShadow: state.isFocused ? "0 0 0 1px #ced4da" : provided.boxShadow,
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "1px 6px",
      backgroundColor: state.isSelected ? "#29AEE6" : "#fff",
      color: state.isSelected ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: state.isSelected ? "#0056b3" : "#e9ecef",
        color: state.isSelected ? "#fff" : "#000",
      },
    }),
    // singleValue: (provided) => ({
    //   ...provided,
    //   fontSize: "16px",
    //   color: "#495057",
    // }),
  };
  //

  // const getRequestAnalysis = async (page = 1, limit = 10) => {
  //   try {
  //     const api = mobioApi(token);
  //     const response = await api.getRequestedDataAnalysis(
  //       airportId,
  //       null,
  //       null,
  //       null,
  //      page,
  //      limit
  //     );

  //     // setRequestedData(response?.data?.result);
  //     return {
  //       items: response?.data?.result || [],
  //       total: response?.data?.pagination?.totalCount || 0,
  //     };
  //   } catch (error) {
  //     // setRequestedData([]);
  //     return { items: [], total: 0 };

  //   }
  // };

  const getRequestAnalysis = useCallback(
    async (page = 1, limit = 10) => {
      try {
        setLoading(true);
        const api = mobioApi(token);
        const response = await api.getRequestedDataAnalysis(
          airportId,
          null,
          null,
          null,
          page,
          limit,
          sortingOrder,
          integrationType
        );

        return {
          items: response?.data?.result || [],
          total: response?.data?.pagination?.totalCount || 0,
        };
      } catch (error) {
        return { items: [], total: 0 };
      } finally {
        setLoading(false);
      }
    },
    [airportId, token, sortingOrder, integrationType]
  );

  const handlePageChange = useCallback((items) => {
    setRequestedData(items);
  }, []);

  // useEffect(() => {
  //   if (airportId) {
  //     getRequestAnalysis();
  //   }
  // }, [airportId]);
  return (
    <div>
      <div>
        {/* tables area */}
        <div className="">
          {/* Searching Area */}
          <div className="mt-16">
            <div>
              <p className="m-0 font-14 font-400 mb-8">Time Frame</p>
              <div className="flex justify-content-between align-item-center width-full">
                <div className="flex gap-10">
                  <div>
                    <DateRangeField
                      dateValue={new Date().toString()}
                      mode="range"
                    />
                  </div>
                  <div className="w-341">
                    <Select
                      options={dateRanges}
                      placeholder="Select date range"
                      formatOptionLabel={weekFormatOptionLabel}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  </div>
                  <div className="w-256">
                    <Select
                      options={routes}
                      placeholder="Select the route"
                      formatOptionLabel={routeFormatOptionLabel}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  <img
                    src={EditIcon}
                    alt="Edit"
                    style={{
                      width: "27px",
                      height: "27px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div>
                  <SearchingInput className="border-solid-gray " />
                </div>
              </div>
            </div>
          </div>
          {/* loading */}

          {/* table */}
          <div className=" mt-30">
            <table className="custom-table-v3">
              <thead>
                <tr>
                  <th
                    className="cursor-pointer"
                    onClick={() => {
                      if (!loading) {
                        if (sortingOrder && sortingOrder === "asc") {
                          setSortingOrder("desc");
                        } else {
                          setSortingOrder("asc");
                        }
                        setCurrentPage(1);
                      }
                    }}
                  >
                    <div className="flex align-item-center sorting-hover ">
                      <p className="text-no-warp"> Search Requests</p>
                      <div style={{ marginLeft: "4px" }}>
                        {/* <Sort /> */}
                        {sortingOrder != null ? (
                          sortingOrder === "desc" ? (
                            <KeyboardArrowDown style={{ marginTop: "2px" }} />
                          ) : (
                            <KeyboardArrowUp style={{ marginTop: "2px" }} />
                          )
                        ) : (
                          <Sort style={{ marginTop: "2px" }} />
                        )}
                      </div>
                    </div>
                  </th>
                  <th className="">Conversion </th>
                  <th className="" style={{ padding: "0px 20px" }}>
                    Pickup Address
                  </th>
                  <th className="" style={{ padding: "0px 20px" }}>
                    Drop-off Address
                  </th>
                  <th className="" style={{ padding: "0px 20px" }}>
                    Distance{" "}
                  </th>
                  {/* <th className="">Set Rate</th> */}
                  {/* <th className="">Service Level</th> */}
                  <th className=""></th>
                </tr>
              </thead>
              {loading ? (
                <tr>
                  <td
                    colSpan="6"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <CircularProgress size="30px" />
                  </td>
                </tr>
              ) : (
                <tbody>
                  {requestedData?.length>0?requestedData?.map((row, index) => (
                    <tr key={index}>
                      <td>{row?.count}</td>
                      <td>{row?.conversion}</td>

                      <td style={{ width: "200px", padding: "0px 20px" }}>
                        {row?.origin}
                      </td>
                      <td style={{ width: "200px", padding: "0px 20px" }}>
                        {row?.destination}
                      </td>
                      <td style={{ padding: "0px 20px" }}>{row.distance} KM</td>

                      {/* <td>{formatCurrency(parseJsonString(row?.response)?.transportCategory?.price?.currency, row?.rate)}</td> */}
                      {/* <td>
                         {parseJsonString(row?.response)?.transportCategory}
                       </td> */}
                      <td>
                        <td>
                          <DropDown
                            dropDownOpen={anchorEl}
                            setDropDownOpen={setAnchorEl}
                            uniqueId={index + 1}
                            IconRender={() => <img src={HorizontalIcon} />}
                            dropDownShape="horizontal"
                            menu={[
                              {
                                onClick: () => {
                                  navigate(
                                    `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/analysis-list/request-analysis-detail/${row?.origin}/${row?.destination}`,
                                    {
                                      state: row,
                                    }
                                  );
                                },
                                IconRender: () => <RemoveRedEyeOutlined />,
                                title: "MORE DETAILS",
                              },
                              {
                                onClick: () => {
                                  // navigate(
                                  //   `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${id}/airport/${row?.iata}/${row?.id}/mange-location`
                                  // );
                                },
                                IconRender: () => <img src={ManageIcon} />,
                                title: "MAP VIEW",
                              },
                            ]}
                          />
                        </td>
                      </td>
                    </tr>
                  )):<>
                  <tr>
                  <td
                    colSpan="6"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <p>Record not found.</p>
                  </td>
                </tr>
                  </>}
                </tbody>
              )}
            </table>
          </div>

          {/*  */}
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              fetchData={getRequestAnalysis}
              itemsPerPage={10}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              onCurrentPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(RequestAnalysis);
