const excludeBookingComServiceLevel = [
  "Electric Executive People Carrier",
  "Electric People Carrier",
  "Electric Large People Carrier",

  "Electric Exe People Carrier",
  "Electric Lrg People Carrier",
];

const excludeCTripComServiceLevel = [
  "Electric Executive People Carrier",
  "Electric People Carrier",
  "Electric Large People Carrier",

  "Electric Exe People Carrier",
  "Electric Lrg People Carrier",

  "People Carrier",
  // "Executive People Carrier",
  // "Large People Carrier",
  "Electric",
  "Luxury",
  "Large Carrier",
  "Exe Carrier",
  "Electric",

];

export const filterServiceLevel = (
  thirdParty = "Booking.com",
  serviceLevels = []
) => {
  let excludeServiceLevel = [];
  if (thirdParty === "Booking.com") {
    excludeServiceLevel = excludeBookingComServiceLevel;
  } else if (thirdParty === "Trip.com") {
    excludeServiceLevel = excludeCTripComServiceLevel;
  }
  return serviceLevels?.filter(
    (serviceLevel) => !excludeServiceLevel.includes(serviceLevel.name)
  );
};
