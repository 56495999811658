const countryBankValidationRules = [
  {
    country: "NL",
    banks: [
      {
        name: "ING",
        fieldRequired: ["IBAN", "Account Holder Name"],
      },
      {
        name: "REVOLUT",
        fieldRequired: ["IBAN", "Account Holder Name", "BIC", "Account Type"],
      },
    ],
  },
  {
    country: "GB",
    banks: [
      {
        name: "REVOLUT",
        fieldRequired: [
          "Account Number",
          // "Bank Country",
          "Sort Code",
          // "Account Holder Name",
          // "Account Type",
        ],
      },
    ],
  },
];
export const checkPaymentInvoiceValidation = (
  paymentData = {},
  country,
  bank
) => {
  let validationStatus = false;
  if (paymentData?.amount > 0) {
    const getCountry = countryBankValidationRules?.filter(
      (countryFilter) => countryFilter.country == country
    );
    if (getCountry?.length > 0 && getCountry[0]?.banks) {
      const getCountryBankRules = getCountry[0]?.banks?.filter(
        (bankFilter) => bankFilter.name == bank
      );
      if (
        getCountryBankRules?.length > 0 &&
        getCountryBankRules[0]?.fieldRequired?.length > 0
      ) {
        const onboardingDocs = paymentData?.user?.onboarding?.onboardingDocs;
        if (onboardingDocs?.length > 0) {
          const rulesObjs = getCountryBankRules[0]?.fieldRequired?.map(
            (val) => {
              return {
                label: val,
                status: false,
              };
            }
          );

          onboardingDocs?.forEach((onBoardingDoc) => {
            rulesObjs.map((rulesObj) => {
              if (rulesObj.label == onBoardingDoc?.networkDoc?.name) {
                rulesObj.status = true;
              }

              return rulesObj;
            });
          });

          if (rulesObjs?.some((item) => item.status === false)) {
            validationStatus = false;
          } else {
            validationStatus = true;
          }
        } else {
          validationStatus = false;
        }
      } else {
        validationStatus = false;
      }
    } else {
      validationStatus = false;
    }
  }

  return validationStatus;
};
