import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./DashboardLayout";
import DriversListing from "./Onboardings";
import TaxiCompanies from "./TaxiCompanies";
import VehicleListing from "./VehicleListing";
import BookingDetails from "./Bookings";
import OrgUsers from "./OrgUsers";
import { connect } from "react-redux";
import { ReactElement } from "react";
import DispatchersListing from "./DispatchersListing";
import AppSettings from "./AppSettings";
import Drivers from "./Drivers";
import Customers from "./Customers";
import CustomerProfile from "./CustomerProfile";
import IntegrationBooking from "./IntegrationBooking";
import IntegrationDispatcher from "./IntegrationDispatcher";
import IntegrationCommission from "./IntegrationCommission";
import PaymentDetails from "./Payments";
import APIKey from "./APIKey";
import Settings from "./Settings";
import TermsAndConditions from "./AppSettings/TermsAndConditions";
import ManagePassenger from "./ManagePassenger";
import ChangePassword from "./OrgUsers/ChangePassword";
import ChangeCustomerPassword from "./CustomerUser/ChangePassword";
import CustomerUser from "./CustomerUser";
import Reports from "./Reports";
import ManageNetworks from "./Networks";
import SubAdmins from "./SubAdmins";
import SubAdminChangePass from "./SubAdmins/ChangePassword";
import CustomerInvoice from "./CustomerInvoice";
import CustomerContactUs from "./CustomerContactUs";
import PickUpTimings from "./PickupTimings";
import RatesApiLocationManage from "./RatesApiLocationManage";
import RateManage from "./RateManage";
import AddLocation from "./AddLocation";
import AddPolygon from "./AddPolygon";
import CityPage from "./CityPage";
import CityAirportPage from "./CityAirportPage";
import CityAirportDetailPage from "./CityAirportDetailPage";
import RateCountryPage from "./RateCountryPage";
import ManageCardsPage from "./IntegrationBooking/GetBookings/ManageCardsPage";
import PickupInstructionPage from "./PickupInstruction";
import AddPickupInstructionPage from "./PickupInstruction/AddPickupInstruction";
import AvailabilityPage from "./Availability";
import CreateStopSale from "./Availability/CreateStopSale";
import ContactInformationPage from "./ContactInformation";
import DispatcherDetailPage from "./DispatcherDetail";
import AnalysisListPage from "./AnalysisRateAutomationPage";
import AnalysisReportPage from "./AnalysisRateAutomationPage/AnalysisReport";
import CampaignListPage from "./CampaignPage";
import OfferListPage from "./OfferPage";
import CommissionListPage from "./CommissionPage";
import ServiceLevelPage from "./ServiceLevelPage";
import AutoDriverEventPage from "./AutoDriverEventPage";
import AreaOfOperation from "./AreaOfOperationPage";
import IntegrationConnectionPage from "./IntegrationBooking/Integration";
import TestingPage from "./TestingPage";
import AutoDriverEventLocation from "./AutoDriverEventPage/AutoDriverEventLocationPage"
import AutoDriverEventLocationPage from "./AutoDriverEventPage/AutoDriverEventLocationPage";
import RequestAnalysisPerAddressDetail from "./AnalysisRateAutomationPage/tabs/RequestAnalysisPerAddressDetail";


interface Props {
  role: any;
  user:any;
}
function Dashboard({ role, user }: Props): ReactElement {
  console.log("role check", role);

  return (
    <DashboardLayout user={user}>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                role == "Admin"
                  ? "/dashboard/onboardings"
                  : role == "Organization"
                  ? "/dashboard/bookings"
                  : role == "OrgUser"
                  ? "/dashboard/change-pass"
                  : role == "CustomerUser"
                  ? "/dashboard/customer-changePass"
                  : role == "SubAdmin"
                  ? "/dashboard/sub-changePass"
                  : "/dashboard/customer-profile"
              }
            />
          }
        />
        <Route path="/bookings" element={<BookingDetails />} />
        <Route path="/org-users" element={<OrgUsers />} />
        <Route path="/change-pass" element={<ChangePassword />} />
        <Route path="/paiment-details" element={<PaymentDetails />} />
        <Route path="/payments" element={<PaymentDetails />} />
        <Route path="/onboardings" element={<DriversListing />} />
        <Route path="/taxi-companies" element={<TaxiCompanies />} />
        <Route path="/sub-admin" element={<SubAdmins />} />
        <Route path="/sub-changePass" element={<SubAdminChangePass />} />
        <Route path="/vehicles" element={<VehicleListing />} />
        <Route path="/networks" element={<ManageNetworks/>} />
        <Route path="/dispatchers" element={<DispatchersListing />} />
        <Route path="/settings" element={<AppSettings />} />
        <Route
          path="/settings/termsandconditions"
          element={<TermsAndConditions />}
        />
        <Route path="/drivers" element={<Drivers />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/customer-profile" element={<CustomerProfile />} />
        <Route
          path="/customer-changePass"
          element={<ChangeCustomerPassword />}
        />
        <Route path="/customer-bookings" element={<BookingDetails />} />
        <Route path="/customer-users" element={<CustomerUser />} />
        <Route path="/integration" element={<IntegrationBooking />} />
        <Route path="/integration/api-key" element={<APIKey />} />
        <Route path="/customer-invoice" element={<CustomerInvoice />} />
        {/* <Route path="/customer-setting" element={<CustomerSetting />} /> */}
        <Route path="/customer-contact" element={<CustomerContactUs />} />
        <Route
          path="/integration/dispatchers"
          element={<IntegrationDispatcher />}
        />
        <Route
          path="/integration/commission"
          element={<IntegrationCommission />}
        />
        <Route path="/organization/settings" element={<Settings />} />
        <Route path="/organization/reports" element={<Reports/>} />

        <Route path="/manage-passengers" element={<ManagePassenger/>} />
        {/* for getting the booking from third party */}
        <Route path="/integration/get-booking/:integrationType" element={<ManageCardsPage />} />
        {/* Rate biding testing route this will remove once the rate bidding testing done successfully done */}
        <Route path="/testing" element={<TestingPage />} />
      {/* Rate API Integration */}
        <Route path="/integration/connection/:integrationType/" element={<IntegrationConnectionPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId" element={<RateCountryPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:id/city" element={<CityPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:id/city-airport" element={<CityAirportPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/city-airport/:airportId/detail" element={<CityAirportDetailPage />} />
        <Route path="/integration/rates/add-location/:integrationType/:integrationId/:action" element={<AddLocation />} />
        <Route path="/integration/rates/:integrationType/:integrationId/add-polygon" element={<AddPolygon />} />
        {/* <Route path="/integration/rates/edit-location" element={<EditLocation />} /> */}
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/area-operation" element={<AreaOfOperation />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/mange-location" element={<RatesApiLocationManage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/rate-manage" element={<RateManage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/pickup-instruction" element={<PickupInstructionPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/pickup-instruction/add" element={<AddPickupInstructionPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/pickup-time" element={<PickUpTimings />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/availability" element={<AvailabilityPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/create-stop-sale" element={<CreateStopSale />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/contact-information" element={<ContactInformationPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/dispatchers" element={<DispatcherDetailPage />} />
        {/* <Route path="/integration/rates/add-dispatcher" element={<AddDispatcherPage />} /> */}
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/analysis-list" element={<AnalysisListPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/analysis-list/request-analysis-detail/:origin/:destination" element={<RequestAnalysisPerAddressDetail />} />
        <Route path="/integration/rates/analysis-report" element={<AnalysisReportPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/campaign-list" element={<CampaignListPage />} />
        {/* <Route path="/integration/rates/add-campaign" element={<AddCampaignPage />} /> */}
        <Route path="/integration/rates/offer-list" element={<OfferListPage />} />
        {/* <Route path="/integration/rates/add-offer" element={<AddOfferPage />} /> */}
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/commission-list" element={<CommissionListPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/service-level-list" element={<ServiceLevelPage />} />
        <Route path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/auto-driver-event" element={<AutoDriverEventPage />} />
        <Route 
          path="/integration/rates/:integrationType/:integrationId/:country/:countryId/:city/:cityId/airport/:airportIATA/:airportId/auto-driver-event/location" 
          element={<AutoDriverEventLocationPage />} 
        />
      </Routes>
    </DashboardLayout>
  );
}

const mapState = (state: any) => ({
  role: state.userInfo.user.role,
  user: state.userInfo.user
});

export default connect(mapState)(Dashboard);
