import { Requests } from "../../../../services";
import moment from "moment";
import Local from "../../local";

export type ThunkPaymentRepo = {
  CreatePayment: (obj: any, zoneOffset: any) => any;
  GetPaymentByYear: (year: any, weekType: any) => any;
  GetPayments: (page: any, limit: any, searchText: any) => any;
  GetPaidPaymentsByTimeRange: (
    startDate: any,
    endDate: any,
    scope: string
  ) => any;
  SearchByBookingId: (bookingId: any, zoneOffset: any) => any;
  PreviewPayment: (obj: any, zoneOffset: any) => any;
  GenerateXML: (obj: any) => any;
  GetPayinPayments: (isPaid: any, searchText: any) => any;
  CreatePayinPayment: (obj: any) => any;
  PaymentInvoiceDetail: (invoiceId: any) => any;
  UpdatePayinPayment: (id: any) => any;
  PreviewPayinPayment: (obj: any) => any;
  GetPaidPaymentByYear: (year: any) => any;
  imageBase64: (url: any) => any;
  GetCustomerPayinPayments: (
    status: any,
    searchText: any,
    offset: any,
    limit: any
  ) => any;
};

const ThunkPayment: ThunkPaymentRepo = {
  GetPaidPaymentByYear:
    (year) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .getPaidPaymentByYear(year)
        .then((res: any) => {
          return res.data;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    },
  PreviewPayinPayment:
    (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);
      return api
        .previewPayinPayment(obj)
        .then((res: any) => res.data)
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    },
  UpdatePayinPayment: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();

    const api: Requests = mobioApi(token);

    return api
      .updatePayinPayment(id)
      .then((res: any) => {
        alert("Payment approved!");
        return res.data;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.msg) {
            errorMsg = error?.response?.data?.msg;
          }
          alert(errorMsg);
        }
      });
  },
  CreatePayinPayment:
    (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .createPayinPayment(obj)
        .then((res: any) => {
          alert("Payment approved!");
          return res.data;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    },
  PaymentInvoiceDetail:
    (invoiceId) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .getPaymentInvoiceDetail(invoiceId)
        .then((res: any) => {
          // alert("Payment approved!")
          return res.data;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    },
  GetPayinPayments:
    (isPaid, searchText) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .getPayinPayments(isPaid, searchText)
        .then((res: any) => {
          return res.data;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    },
  SearchByBookingId:
    (bookingId, zoneOffset) =>
    (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .searchByBookingId(bookingId, zoneOffset)
        .then((res: any) => {
          return res.data;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    },
  GetPayments:
    (page, limit, searchText) =>
    (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .getPayments(page, limit, searchText)
        .then((res: any) => {
          return res.data;
        })
        .catch((error: any) => {
          let errorMsg = error;
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
          alert(errorMsg);
        });
    },
  GetPaidPaymentsByTimeRange:
    (startDate, endDate, scope) =>
    (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .getPaidPaymentsByTimeRange(startDate, endDate, scope)
        .then((res: any) => {
          return res.data;
        })
        .catch((error: any) => {
          let errorMsg = error;
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
          alert(errorMsg);
        });
    },
  GetPaymentByYear:
    (year, weekType = "weekStart") =>
    (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .getPaymentByYear(year, weekType)
        .then((res: any) => {
          return res.data;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
          }

          throw new Error(error || "Failed to fetch payment data");
        });
    },
  CreatePayment:
    (obj, zoneOffset) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);

      return api
        .createPayment(obj, zoneOffset)
        .then((res: any) => {
          alert("Payment approved!");
          return res.data;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
          }
        });
    },
  PreviewPayment: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();

    const api: Requests = mobioApi(token);

    return api
      .previewPayment(obj)
      .then((res: any) => res.data)
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.msg) {
            errorMsg = error?.response?.data?.msg;
          }
          alert(errorMsg);
        }
      });
  },
  GenerateXML: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api: Requests = mobioApi(token);
    return api
      .generatedXML(obj)
      .then((res: any) => res.data)
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.msg) {
            errorMsg = error?.response?.data?.msg;
          }
          alert(errorMsg);
        }
      });
  },
  imageBase64: (url) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();

    const api: Requests = mobioApi(token);
    return api
      .imageBase64(url)
      .then((res: any) => {
        return res.data;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.msg) {
            errorMsg = error?.response?.data?.msg;
          }
          alert(errorMsg);
        }
      });
  },
  // Customer Payment detail
  GetCustomerPayinPayments:
    (status, searchText, offset, limit) =>
    (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api: Requests = mobioApi(token);
      dispatch(Local.PaymentPayIn.api.request());
      return api
        .getCustomerPayinPayments(status, searchText, offset, limit)
        .then((res: any) => {
          dispatch(Local.PaymentPayIn.api.response(res.data));
          return true;
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            // let errorMsg = error;
            // if (error?.response?.data?.msg) {
            //   errorMsg = error?.response?.data?.msg;
            // }
            // alert(errorMsg);
            const {
              response: { data },
            } = error;
            dispatch(Local.PaymentPayIn.api.error("Request Failed", data.msg));
          }
        });
      },
};

export default ThunkPayment;
