import React, { useState, useEffect, ChangeEvent, CSSProperties, useRef } from 'react';
import { makeStyles, Theme } from "@material-ui/core/styles";
import ImageUploadIcon from '../../assets/icons/imageUploadIcon.svg';
import ImageUploadButton from './ImageUploadButton';
import RemoveImageIcon from '../../assets/icons/cross.svg';

type Props = {
  label?: string | null;
  accept: string;
  callback: (files: FileList | null) => void;
  style?: CSSProperties;
  cancel?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  setSubmitDisabled?: Function;
  hasFiles?: FileList | null;
  profileImage: string | File | null;
};

const defaultProps = {
  label: 'Click here to upload a file',
  accept: '*',
  disabled: false
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    padding: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '0.5rem',
    cursor: 'pointer',
    "&:hover": {
      background: "#e7e7e7",
      width: '100%',
    },
    [theme.breakpoints.down('md')]: { 
      width: '70%',
    },
  },
  previewImage: {
    width: '100%',
    maxHeight: 200,
    objectFit: 'contain'
  },
  container: {
    position: 'relative', 
    border: "1px dashed #e3e0e0",
    borderRadius: 5,
    borderWidth: '2px',  
    width: '30%',
     // Add media query for screens < 768px
  [theme.breakpoints.down('sm')]: {  // 'sm' breakpoint is 600px by default
    width: '60%',
  },
  // Or for exact 768px:
  '@media (max-width: 791px)': {
    width: '50%',
  },
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  removeIcon: {
    position: 'absolute',
    top: 10,
    right: 20,
    cursor: 'pointer',
    zIndex: 2,
    width: 24,
    height: 24
  }
  
}));

const FileUploaderDriver: React.FC<Props> = ({
  label,
  accept,
  callback,
  style,
  disabled,
  setSubmitDisabled,
  hasFiles,
  profileImage,
}) => {
  const [fileName, setFileName] = useState<string>('');
  const [files, setFiles] = useState<FileList | null>(null);
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (typeof profileImage === 'string') {
      setPreviewUrl(profileImage);
    } else if (profileImage instanceof File) {
      setPreviewUrl(URL.createObjectURL(profileImage));
    } else if (files && files.length > 0) {
      setPreviewUrl(URL.createObjectURL(files[0]));
    } else {
      setPreviewUrl(null);
    }
  }, [files, profileImage]);

  const handleRemoveImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setFiles(null);
    setFileName('');
    setPreviewUrl(null);
    callback(null);
  };
  const handleContainerClick = () => {
    if (!previewUrl && inputRef.current) {
      inputRef.current.click();
    }
  };
  return (
    <div className={`${classes.container} mb-5`} style={style}
    onClick={handleContainerClick}>
      {previewUrl && (
        <img 
          className={classes.removeIcon}
          onClick={handleRemoveImage} 
          src={RemoveImageIcon} 
          alt="removeIcon" 
        />
      )}
      
      <label
        htmlFor="formFile"
        className={classes.label}
      >
        {previewUrl ? (
          <>
            <img
              src={previewUrl}
              className={classes.previewImage}
              alt="preview"
            />
            {fileName && <p>{fileName}</p>}
          </>
        ) : (
          <>
            <img src={ImageUploadIcon} alt="image upload icon" />
            <ImageUploadButton
              backgroundColor="#ffffff"
              border="1px solid #0F91D2B2"
              fontSize={10}
              color="#0F91D2"
              name="file upload"
            />
          </>
        )}
      </label>

      <input
        id="formFile"
        ref={inputRef}
        name="upload"
        type="file"
        className="form-control"
        hidden
        accept={accept}
        disabled={disabled}
        onChange={(e) => {
          const selectedFiles = e.target.files;
          if (selectedFiles && selectedFiles.length > 0) {
            setFiles(selectedFiles);
            setFileName(selectedFiles[0].name);
            callback(selectedFiles);
          }
        }}
      />
    </div>
  );
};

FileUploaderDriver.defaultProps = defaultProps;

export default FileUploaderDriver;