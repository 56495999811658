// import { ArrowLeft, ArrowRight } from "@material-ui/icons";
// import { useEffect, useState } from "react";
// import ReactPaginate from "react-paginate";

// interface PaginationProps<T> {
//   fetchData: (
//     page: number,
//     itemsPerPage: number
//   ) => Promise<{ items: T[]; total: number }>;
//   itemsPerPage?: number;
//   onPageChange?: (items: T[]) => void;
//   className?: string;
//   paginationClassName?: string;
// }

// export default function Pagination<T>({
//   fetchData,
//   itemsPerPage = 10,
//   onPageChange,
//   paginationClassName = "",
// }: PaginationProps<T>) {
//   const [currentItems, setCurrentItems] = useState<T[]>([]);
//   const [totalItems, setTotalItems] = useState<number>(0);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [currentPage, setCurrentPage] = useState<number>(0);

//   useEffect(() => {
//     const loadData = async () => {
//       setLoading(true);
//       try {
//         const { items, total } = await fetchData(currentPage, itemsPerPage);
//         setCurrentItems(items);
//         setTotalItems(total);
//         onPageChange?.(items);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     loadData();
//   }, [fetchData, currentPage, itemsPerPage, onPageChange]);

//   const pageCount = Math.ceil(totalItems / itemsPerPage);

//   const handlePageClick = (event: { selected: number }) => {
//     setCurrentPage(event.selected);
//   };

//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//     <ReactPaginate
//       breakLabel={"..."}
//       nextLabel={
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             backgroundColor: "#F5F5F5",
//             padding: "4px 6px",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//           onMouseEnter={(e) => {
//             e.currentTarget.style.backgroundColor = "#48b3e8";
//             e.currentTarget.style.color = "white";
//           }}
//           onMouseLeave={(e) => {
//             e.currentTarget.style.backgroundColor = "#F5F5F5";
//             e.currentTarget.style.color = "black";
//           }}
//         >
//           <p style={{ fontSize: "8px", fontWeight: "normal", margin: 0 }}>Next</p>
//           <ArrowRight style={{ width: "8px", height: "8px", marginLeft: "4px" }} />
//         </div>
//       }
//       onPageChange={handlePageClick}
//       pageRangeDisplayed={3}
//       pageCount={pageCount}
//       previousLabel={
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             backgroundColor: "#F5F5F5",
//             padding: "4px 6px",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//           onMouseEnter={(e) => {
//             e.currentTarget.style.backgroundColor = "#48b3e8";
//             e.currentTarget.style.color = "white";
//           }}
//           onMouseLeave={(e) => {
//             e.currentTarget.style.backgroundColor = "#F5F5F5";
//             e.currentTarget.style.color = "black";
//           }}
//         >
//           <ArrowLeft style={{ width: "8px", height: "8px", marginRight: "4px" }} />
//           <p style={{ fontSize: "8px", fontWeight: "normal", margin: 0 }}>Prev</p>
//         </div>
//       }
//       containerClassName={`pagination ${paginationClassName}`}
//       activeClassName="active"
//       disabledClassName="disabled"
//     />
//     <select
//       id="minutes"
//       style={{
//         cursor: "pointer",
//         padding: "4px 6px",
//         fontWeight: 500,
//         fontSize: "8px",
//         color: "#333333",
//         borderRadius: "4px",
//         outline: "none",
//         backgroundColor: "#F5F5F5",
//         marginLeft: "8px",
//       }}
//     >
//       <option>7/page</option>
//       <option>10/page</option>
//       <option>15/page</option>
//       <option>20/page</option>
//     </select>
//     {/* <label style={{ fontSize: "8px", marginLeft: "8px" }}>
//       Go to
//       <input
//         type="number"
//         style={{
//           marginLeft: "8px",
//           backgroundColor: "#F5F5F5",
//           outline: "none",
//           borderRadius: "4px",
//           height: "20px",
//           width: "50px",
//           paddingLeft: "6px",
//           lineHeight: "normal",
//         }}
//       />
//     </label> */}
//   </div>
//   );
// }

// v2
// import { ArrowLeft, ArrowRight } from "@material-ui/icons";
// import { useEffect, useState } from "react";
// import ReactPaginate from "react-paginate";

// interface PaginationProps<T> {
//   fetchData: (
//     page: number,
//     itemsPerPage: number
//   ) => Promise<{ items: T[]; total: number }>;
//   itemsPerPage?: number;
//   onPageChange?: (items: T[]) => void;
//   className?: string;
//   paginationClassName?: string;
// }

// export default function Pagination<T>({
//   fetchData,
//   itemsPerPage = 10,
//   onPageChange,
//   paginationClassName = "",
// }: PaginationProps<T>) {
//   const [currentItems, setCurrentItems] = useState<T[]>([]);
//   const [totalItems, setTotalItems] = useState<number>(0);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [currentPage, setCurrentPage] = useState<number>(0);

//   // Reset to the first page when itemsPerPage changes
//   useEffect(() => {
//     setCurrentPage(0);
//   }, [itemsPerPage]);

//   // Fetch data when currentPage, itemsPerPage, or fetchData changes
//   useEffect(() => {
//     console.log("Fetching data for page:", currentPage); // Debugging
//     const loadData = async () => {
//       setLoading(true);
//       try {
//         const { items, total } = await fetchData(currentPage, itemsPerPage);
//         setCurrentItems(items);
//         setTotalItems(total);
//         onPageChange?.(items);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     loadData();
//   }, [fetchData, currentPage, itemsPerPage, onPageChange]);

//   const pageCount = Math.ceil(totalItems / itemsPerPage);

//   const handlePageClick = (event: { selected: number }) => {
//     console.log("Selected page:", event.selected); // Debugging
//     setCurrentPage(event.selected);
//   };

//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <ReactPaginate
//         breakLabel={"..."}
//         nextLabel={
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               backgroundColor: "#F5F5F5",
//               padding: "4px 6px",
//               borderRadius: "4px",
//               cursor: "pointer",
//             }}
//             onMouseEnter={(e) => {
//               e.currentTarget.style.backgroundColor = "#48b3e8";
//               e.currentTarget.style.color = "white";
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.backgroundColor = "#F5F5F5";
//               e.currentTarget.style.color = "black";
//             }}
//           >
//             <p style={{ fontSize: "8px", fontWeight: "normal", margin: 0 }}>Next</p>
//             <ArrowRight style={{ width: "8px", height: "8px", marginLeft: "4px" }} />
//           </div>
//         }
//         onPageChange={handlePageClick}
//         pageRangeDisplayed={3}
//         pageCount={pageCount}
//         previousLabel={
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               backgroundColor: "#F5F5F5",
//               padding: "4px 6px",
//               borderRadius: "4px",
//               cursor: "pointer",
//             }}
//             onMouseEnter={(e) => {
//               e.currentTarget.style.backgroundColor = "#48b3e8";
//               e.currentTarget.style.color = "white";
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.backgroundColor = "#F5F5F5";
//               e.currentTarget.style.color = "black";
//             }}
//           >
//             <ArrowLeft style={{ width: "8px", height: "8px", marginRight: "4px" }} />
//             <p style={{ fontSize: "8px", fontWeight: "normal", margin: 0 }}>Prev</p>
//           </div>
//         }
//         containerClassName={`pagination ${paginationClassName}`}
//         activeClassName="active"
//         disabledClassName="disabled"
//       />
//       <select
//         id="minutes"
//         style={{
//           cursor: "pointer",
//           padding: "4px 6px",
//           fontWeight: 500,
//           fontSize: "8px",
//           color: "#333333",
//           borderRadius: "4px",
//           outline: "none",
//           backgroundColor: "#F5F5F5",
//           marginLeft: "8px",
//         }}
//       >
//         <option>7/page</option>
//         <option>10/page</option>
//         <option>15/page</option>
//         <option>20/page</option>
//       </select>
//     </div>
//   );
// }

// V3

import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

interface PaginationProps<T> {
  fetchData: (
    page: number,
    itemsPerPage: number
  ) => Promise<{ items: T[]; total: number }>;
  itemsPerPage?: number;
  onPageChange?: (items: T[]) => void;
  className?: string;
  paginationClassName?: string;
  currentPage?: number; // Add currentPage prop
  onCurrentPageChange: (page: number) => void; // Add onPageChange prop
}

export default function Pagination<T>({
  fetchData,
  itemsPerPage = 10,
  onPageChange,
  paginationClassName = "",

  currentPage = 1,
  onCurrentPageChange,
}: PaginationProps<T>) {
  const [currentItems, setCurrentItems] = useState<T[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  // const [currentPage, setCurrentPage] = useState<number>(0);

  // Reset to the first page when itemsPerPage changes
  useEffect(() => {
    // setCurrentPage(0);
    onCurrentPageChange(1);
  }, [itemsPerPage]);

  // Fetch data when currentPage, itemsPerPage, or fetchData changes
  useEffect(() => {
    console.log("Fetching data for page:", currentPage); // Debugging (convert to one-based)
    const loadData = async () => {
      setLoading(true);
      try {
        // Convert currentPage to one-based indexing for the backend
        const { items, total } = await fetchData(currentPage, itemsPerPage);
        setCurrentItems(items);
        setTotalItems(total);
        onPageChange?.(items);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [fetchData, currentPage, itemsPerPage, onPageChange]);

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    console.log("Selected page:", event.selected); // Debugging (zero-based)
    // setCurrentPage(event.selected); // Use zero-based index directly
    onCurrentPageChange(event.selected + 1);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactPaginate
        breakLabel={"..."}
        nextLabel={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              padding: "4px 6px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#48b3e8";
              e.currentTarget.style.color = "white";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#F5F5F5";
              e.currentTarget.style.color = "black";
            }}
          >
            <p style={{ fontSize: "8px", fontWeight: "normal", margin: 0 }}>
              Next
            </p>
            <ArrowRight
              style={{ width: "8px", height: "8px", marginLeft: "4px" }}
            />
          </div>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              padding: "4px 6px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#48b3e8";
              e.currentTarget.style.color = "white";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#F5F5F5";
              e.currentTarget.style.color = "black";
            }}
          >
            <ArrowLeft
              style={{ width: "8px", height: "8px", marginRight: "4px" }}
            />
            <p style={{ fontSize: "8px", fontWeight: "normal", margin: 0 }}>
              Prev
            </p>
          </div>
        }
        containerClassName={`pagination ${paginationClassName}`}
        activeClassName="active"
        disabledClassName="disabled"
      />
      {/* <select
        id="minutes"
        style={{
          cursor: "pointer",
          padding: "4px 6px",
          fontWeight: 500,
          fontSize: "8px",
          color: "#333333",
          borderRadius: "4px",
          outline: "none",
          backgroundColor: "#F5F5F5",
          marginLeft: "8px",
        }}
      >
        <option>7/page</option>
        <option>10/page</option>
        <option>15/page</option>
        <option>20/page</option>
      </select> */}
    </div>
  );
}
