import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../components/commons/ButtonGrouping";
import { Box } from "@material-ui/core";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, ExpandMore, InfoOutlined } from "@material-ui/icons";
import HeaderDescription from "../../../components/commons/HeaderDescription";
import Select from "react-select";
import RenderAnalysisScreen from "./RenderAnalysisScreen";

const rateMenu = [
  // {
  //   title: "Dashboard",
  //   value: "dashboard",
  //   display: true,
  // },
  {
    title: "Request Analysis",
    value: "request_analysis",
    display: true,
  },
  // {
  //   title: "Operational Efficiency",
  //   value: "operational_efficiency",
  //   display: true,
  // },
  // {
  //   title: "Financial Performance",
  //   value: "financial_performance",
  //   display: true,
  // },
  // {
  //   title: "Geographical Analysis",
  //   value: "geographical_analysis",
  //   display: true,
  // },
];

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [menuType, setMenuType] = useState("request_analysis");
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();

  const paths = [
    {
      name: "Analysis & Rate Automation ",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
  ];
  const secondaryPaths = [
    {
      name: "Netherlands",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },
    {
      name: "Manage AMS ",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
    {
      name: "Analysis & Rate Automation",
      // url: "/dashboard/integration/rates/city",
    },
  ];

  return (
    <div>
      <div className="mt-18">
        <div>
          <Breadcrumb paths={paths} />
          <div className="breadcrumb-description">
            Dispatchers can be assigned to bookings and use the driver app
          </div>
          <div className="mt-12 ml-6">
            <Breadcrumb paths={secondaryPaths} secondary={true} />
          </div>
        </div>
      </div>

      {/*  */}

      <div className="mt-14">
        {" "}
        <StyledToggleButtonGroup
          value={menuType}
          exclusive
          onChange={(e, value) => {
            value != null && setMenuType(value);
          }}
        >
          {rateMenu
            ?.filter((fm) => fm.display)
            .map((menu) => {
              return (
                <StyledToggleButton
                  disabled={loading}
                  key={0}
                  value={menu.value}
                >
                  {menu.title}
                </StyledToggleButton>
              );
            })}
        </StyledToggleButtonGroup>
      </div>
      <div>
        <RenderAnalysisScreen type={menuType} />
      </div>
    </div>
  );
};

export default Index;
