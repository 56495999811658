import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserIcon from "../../../assets/icons/auto-driver-user-icon.svg";
import UserGroupIcon from "../../../assets/icons/UserGroup.svg";
import AutoDriverVehicleIcon from "../../../assets/icons/vehicle.svg";
import ContactIcon from "../../../assets/icons/auto-contact-icon.svg";
import Breadcrumb from "../../../components/BreadCrumb";
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import { Add } from "@material-ui/icons";
import {
  ErrorMessage,
  Field,
  FormikHelpers,
  FormikProvider,
  useFormik,
} from "formik";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import MOSwitch from "../../../components/commons/MOSwitch";
import * as Yup from "yup";
import { toastMessage } from "../../../helpers/toastMessage";
import FileUploaderDriver from "../../../components/commons/FileUploadDriver";
import ToolTipMessage from "../../../components/commons/ToolTipMessage";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";

interface Driver {
  firstName: string;
  lastName: string;
  phone: string;
  profileImage: string | File | null;
}

interface Car {
  brand: string;
  model: string;
  licensePlateNo: string;
  color: string;
}

interface DriverEvent {
  isActive: boolean;
  mins: number;
  latitude: number;
  longitude: number;
}

interface AutoDriverEvent {
  driver: Driver;
  car: Car;
  driverDepartedToAirportEvent: DriverEvent;
  driverArrivedToAirportEvent: DriverEvent;
  driverNoShowToAirportEvent: DriverEvent;
}

interface AutoDriverEventProps {
  token: string;
}

export interface Brand {
  id: string
  brandName: string
  createdAt: string
  updatedAt: string
  cars: CarModel[]
}

export interface CarModel {
  id: string
  carName: string
  category: string
  seatingCapacity: number
  year: number
  brandId: string
  color?: string
  createdAt: string
  updatedAt: string
}

// Define a reusable schema for the event objects
const driverEventSchema = Yup.object().shape({
  isActive: Yup.boolean().required(),
  mins: Yup.number()
  .when('isActive', {
    is: (val: boolean) => val === true,
    then: () => Yup.number()
      .required("Mins are required when active")
      .min(0, "minutes cannot be negative")
      .test(
        'not-negative-zero',
        'Negative zero (-0) is not allowed',
        value => !Object.is(value, -0)  // Explicit check for -0
      ),
    otherwise: schema => schema
    .nullable()
    .transform((value, originalValue) => originalValue === '' ? null : value)
  }),
  latitude: Yup.string()
  .when('isActive', {
    is: (val: boolean) => val === true,
    then: () => Yup.string().required("Latitude is required when active")
    .test(
      'is-valid-lat',
      'Invalid latitude (must be between -90 and 90)',
      value => {
        const num = parseFloat(value);
        return !isNaN(num) && num >= -90 && num <= 90;
      }
    ),
  }),
  longitude: Yup.string()
  .when('isActive', {
    is: (val: boolean) => val === true,
    then: () => Yup.string()
    .required("Latitude is required when active")
    .test(
      'is-valid-lng',
      'Invalid longitude (must be between -180 and 180)',
      value => {
        const num = parseFloat(value);
        return !isNaN(num) && num >= -180 && num <= 180;
      }
    )
  }),
});

// Main validation schema
const validationSchema = Yup.object().shape({
  driver: Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .matches(
        /^[A-Za-z\s'-]+$/,
        "Special characters are not allowed"
      )  
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .matches(
        /^[A-Za-z\s'-]+$/,
        "Special characters are not allowed"
      )  
      .required("Last name is required"),
    phone: Yup.string()
    .required("Phone number is required")
    .matches(
      /^\+?\d+$/,  // Regex pattern
      "Only numbers and '+' prefix allowed"
    )
    .min(11, "Phone number must be at least 11 digits")
    .max(13, "Phone number cannot exceed 13 digits")
    .test(
      'no-special-chars',
      'Special characters are not allowed',
      value => !/[^\d+]/.test(value)  // Explicitly block other characters
    ),
    profileImage: Yup.mixed()
    .nullable() // Add this to allow null
    .test(
      "file-or-url",
      "Profile image must be a valid URL or file",
      (value) => {
        // Allow null values
        if (value === null) return true;
        
        // If value exists, validate it
        if (typeof value === "string") {
          try {
            new URL(value);
            return true;
          } catch (error) {
            return false;
          }
        }
        if (value instanceof File) return true;
        return false;
      }
    ),
  }),
    car: Yup.object().shape({
    brand: Yup.string().required("Brand is required"),
    model: Yup.string().required("Model is required"),
    licensePlateNo: Yup.string()
    .min(1, "Too Short!")
    .max(20, "Too Long")
    .required("License plate number is required"),
    color: Yup.string()
    .min(2, "Too Short!")
    .max(16, "Too Long")
    .required("Color is required"),
  }),
  driverDepartedToAirportEvent: driverEventSchema,
  driverArrivedToAirportEvent: driverEventSchema,
  driverNoShowToAirportEvent: driverEventSchema,
});

const Index: FC<AutoDriverEventProps> = ({ token }) => {
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const [brandNames, setBrandNames] = useState<string[]>([]);
  const navigation = useNavigate();
  const uploadImageToAWS = (file: File): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("image", file);
        const api = mobioApi(token);
        const response = await api.upload(formData);
        resolve(response.data.url);
      } catch (error) {
        toastMessage("error", "Error in uploading profile image of driver");
        reject(error);
      }
    });
  };

  const handleSubmit = async (
    values: AutoDriverEvent,
    { setSubmitting }: FormikHelpers<AutoDriverEvent>
  ) => {
    setSubmitting(true);
    try {
      const payload = {
        firstName: values.driver.firstName,
        lastName: values.driver.lastName,
        contact: values.driver.phone,
        imageUrl: values.driver.profileImage,
        brandName: values.car.brand,
        carModel: values.car.model,
        color: values.car.color,
        licensePlateNumber: values.car.licensePlateNo,
        rideEventIsActive: values.driverDepartedToAirportEvent.isActive,
        rideMints: values.driverDepartedToAirportEvent.mins,
        rideLng: values.driverDepartedToAirportEvent.longitude || null,
        rideLat: values.driverDepartedToAirportEvent.latitude || null,
        arrivedEventIsActive: values.driverArrivedToAirportEvent.isActive,
        arrivedMints: values.driverArrivedToAirportEvent.mins,
        arrivedLng: values.driverArrivedToAirportEvent.longitude || null,
        arrivedLat: values.driverArrivedToAirportEvent.latitude || null,
        noShowEventIsActive: values.driverNoShowToAirportEvent.isActive,
        noShowMints: values.driverNoShowToAirportEvent.mins,
        noShowLng: values.driverNoShowToAirportEvent.longitude || null,
        noShowLat: values.driverNoShowToAirportEvent.latitude || null,
        airportId: airportId,
      };
      if(values.driver.profileImage){
        const imageUrl: string =  typeof values.driver.profileImage === 'string' ? values.driver.profileImage : await uploadImageToAWS(values.driver.profileImage);
        payload.imageUrl = imageUrl 
      }
      const api = mobioApi(token);
      const response = await api.addAutoDriverEvent(payload);
      toastMessage("success", response?.data?.msg);
      // // Append the airport ID
      // formData.append('airportId', airportId);
    } catch (error) {
      toastMessage("error", error);
    }
    setSubmitting(false);
    setSubmitting(false);
  };

  const formik = useFormik<AutoDriverEvent>({
    
    initialValues: {
      driver: {
        firstName: "",
        lastName: "",
        phone: "",
        profileImage: null,
      },
      car: {
        brand: "",
        model: "",
        licensePlateNo: "",
        color: "",
      },
      driverDepartedToAirportEvent: {
        isActive: false,
        mins: 0,
        latitude: 0,
        longitude: 0,
      },
      driverArrivedToAirportEvent: {
        isActive: false,
        mins: 0,
        latitude: 0,
        longitude: 0,
      },
      driverNoShowToAirportEvent: {
        isActive: false,
        mins: 0,
        latitude: 0,
        longitude: 0,
      },
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
    {
      name: "Auto Driver Events",
    },
  ];
  const getAutoDriverEvent = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAutoDriverEvent(airportId);
      const carBrandNames = await api.getAllBrands();
      
      setBrandNames(carBrandNames.data.map((carBrandName: Brand) => carBrandName.brandName));

      const apiData = response?.data?.data;

      if (apiData) {
        formik.setValues({
          driver: {
            firstName: apiData.firstName || "",
            lastName: apiData.lastName || "",
            phone: apiData.contact || "",
            profileImage: apiData.imageUrl || null,
          },
          car: {
            brand: apiData.brandName || "",
            model: apiData.carModel || "",
            color: apiData.color || "",
            licensePlateNo: apiData.licensePlateNumber || "",
          },
          driverDepartedToAirportEvent: {
            isActive: apiData.rideEventIsActive || false,
            mins: Number(apiData.rideMints) || 0,
            latitude: apiData.rideLat?.toString() || "",
            longitude: apiData.rideLng?.toString() || "",
          },
          driverArrivedToAirportEvent: {
            isActive: apiData.arrivedEventIsActive || false,
            mins: Number(apiData.arrivedMints) || 0,
            latitude: apiData.arrivedLat?.toString() || "",
            longitude: apiData.arrivedLng?.toString() || "",
          },
          driverNoShowToAirportEvent: {
            isActive: apiData.noShowEventIsActive || false,
            mins: Number(apiData.noShowMints) || 0,
            latitude: apiData.noShowLat?.toString() || "",
            longitude: apiData.noShowLng?.toString() || "",
          },
        });
      }
    } catch (error) {
      toastMessage("error", "Failed to load driver data");
      console.error("Error fetching driver data:", error);
    }
  };
  useEffect(() => {
    getAutoDriverEvent();
  }, []);

  

  const handleLocation = (type: 'rideStart' | 'arrived' | 'noShow') => {
    let coordinates;
    
    switch(type) {
      case 'rideStart':
        coordinates = {
          lat: formik.values.driverDepartedToAirportEvent.latitude,
          lng: formik.values.driverDepartedToAirportEvent.longitude
        };
        break;
      case 'arrived':
        coordinates = {
          lat: formik.values.driverArrivedToAirportEvent.latitude,
          lng: formik.values.driverArrivedToAirportEvent.longitude
        };
        break;
      case 'noShow':
        coordinates = {
          lat: formik.values.driverNoShowToAirportEvent.latitude,
          lng: formik.values.driverNoShowToAirportEvent.longitude
        };
        break;
      default:
        coordinates = { lat: '', lng: '' };
    }
    navigation('location', {
      state: {
        type,
        coordinates,
        title: `${type.charAt(0).toUpperCase() + type.slice(1)} Location`
      }
    });
  };

  return (
    <>
      <div>
        <div
          className="mt-18"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Auto Driver Events",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
            />
            <div className="breadcrumb-description">
              Need this section to set up Auto events for each location
            </div>
            <div className="mt-8 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          </div>
        </div>
        {/* body */}
        <div className="bg-white auto-driver-spacing-spacing border-radius-8 ">
          <div className="">
          {/* form */}

          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              {/* Header */}
              <div className="flex gap-10" >
                <div>
                  <img src={UserGroupIcon} alt="UserIcon" />
                </div>
                <h1 className="font-14 font-500 mb-0">Driver Details</h1>
              </div>
              <div className="flex gap-32 mt-16">
                {/* First Name */}
                <div className="flex-1 min-w-0 basis-[30%]"> {/* Add min-w-0 for proper shrinking */}
                <div 
                  className="auto-driver-event-driver-detail relative group" 
                  // Add click handler and focus styles
                  onClick={(e) => {
                    if (e.target === e.currentTarget) {
                      document.getElementById('driver.firstName')?.focus()
                    }
                  }}
                >
                  {/* Wrap content in label for better click handling */}
                  <label 
                    htmlFor="driver.firstName" 
                    className="flex align-items-center gap-2 flex-1 cursor-text"
                  >
                    <img src={UserIcon} alt="UserIcon" className="shrink-0" />
                    <div className="input_field flex-1 min-w-0">  {/* Expand to full width */}
                      <Field
                        name="driver.firstName"
                        className="input-field-styles flex-1 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        // Add focus styles
                        type="text"
                        placeholder="Jenny"
                        value={formik.values.driver.firstName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue("driver.firstName", e.target.value);
                        }}
                        id="driver.firstName"
                      />
                    </div>
                  </label>
                </div>
                <ErrorMessage name="driver.firstName">
                  {(msg) => <div style={{ color: "red", margin: "5px" }}>{msg}</div>}
                </ErrorMessage>
              </div>
              
              {/* Last Name */}
              <div className="flex-1 min-w-0 basis-[30%]">
              <div 
                className="auto-driver-event-driver-detail relative group"
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    document.getElementById('driver.lastName')?.focus()
                  }
                }}
              >
                <label 
                  htmlFor="driver.lastName" 
                  className="flex items-center gap-2 flex-1 cursor-text"
                >
                  <img src={UserIcon} alt="UserIcon" className="shrink-0" />
                  <div className="input_field flex-1 min-w-0">
                    <Field
                      name="driver.lastName"
                      type="text"
                      placeholder="Wilson"
                      value={formik.values.driver.lastName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.setFieldValue("driver.lastName", e.target.value);
                      }}
                      className="input-field-styles flex-1 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      id="driver.lastName"
                    />
                  </div>
                </label>
              </div>
              <ErrorMessage name="driver.lastName">
                {(msg) => <div style={{ color: "red", margin: "5px" }}>{msg}</div>}
              </ErrorMessage>
            </div>
                
                {/* Phone Number */}
                <div className="flex-1 min-w-0 basis-[30%]">
              <div 
                className="auto-driver-event-driver-detail relative group"
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    document.getElementById('driver.phone')?.focus()
                  }
                }}
              >
                <label 
                  htmlFor="driver.phone" 
                  className="flex items-center gap-2 flex-1 cursor-text"
                >
                  <img src={ContactIcon} alt="ContactIcon" className="shrink-0" />
                  <div className="input_field flex-1 min-w-0">
                    <Field
                      type="text"
                      maxLength={13}
                      name="driver.phone"
                      placeholder="+31 6463563"
                      value={formik.values.driver.phone}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        if (value === '' || /^\+?\d*$/.test(value)) {
                          formik.setFieldValue("driver.phone", value);
                        }
                      }}
                      className="input-field-styles flex-1 w-full focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      id="driver.phone"
                    />
                  </div>
                </label>
              </div>
              <ErrorMessage name="driver.phone">
                {(msg) => <div style={{ color: 'red', margin: "5px" }}>{msg}</div>}
              </ErrorMessage>
            </div>
              </div>

              {/* Setup Time */}
              <div className="flex  gap-10 mt-40 mb-20">
                <div>
                  <img
                    src={AutoDriverVehicleIcon}
                    alt="auto driver vehicle icon"
                  />
                </div>
                <p className="font-14 font-500">Vehicle Details</p>
              </div>

               <div>
                {/* Ride Detail */}
                <div className="box-shadow-all-3 auto-driver-field-spacing-spacing">
                  <div className="flex gap-24  ">
                    <div className="flex-1 ">
                      <div className="input_field ">
                        <p className="text-gray font-16 font-500 mb-12">
                          Brand
                        </p>
                        <Field 
                          as="select" 
                          className="border-gray-color optionStyle custom-select"
                          name="car.brand"  // Changed to match your form structure
                          value={formik.values.car.brand}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("car.brand", e.target.value);
                          }}
                          style={{
                            border: '1px solid #878787 !important',
                          }}
                        >
                          <option  value="">Select Brand</option>
                          {brandNames?.map((brandName) => (
                            <option
                              key={brandName} 
                              value={brandName}
                            >
                              {brandName}
                            </option>
                          ))}
                        </Field>

                      </div>
                    <ErrorMessage name="car.brand">
                    {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                  </ErrorMessage>
                    </div> 

                    <div className="flex-1">
                      <div className="input_field ">
                        <p className="text-gray font-16 font-500 mb-12">
                          Car Model
                        </p>
                        <Field 
                          as="select" 
                          className="border-gray-color optionStyle custom-select-car"
                          name="car.model"
                          value={formik.values.car.model}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("car.model", e.target.value);
                          }}
                           style={{
                            border: '1px solid #878787 !important',
                          }}
                        >
                          {Array.from(
                            { length: new Date().getFullYear() - 2000 + 1 },
                            (_, i) => 2000 + i
                          )
                          .reverse() // To show latest years first
                          .map((year) => (
                            <option key={year} value={year.toString()}>
                              {year}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <ErrorMessage name="car.model">
                        {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="flex gap-24 mt-16">
                    <div className="flex-1">
                      <div className="input_field ">
                        <p className="text-gray font-16 font-500 mb-12">
                          License Plate Number
                        </p>
                        <Field
                          type="text"
                          name="car.licensePlateNo"
                          className= "input-field-styles"
                          placeholder="G-123-D"
                          value={formik.values.car.licensePlateNo}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("car.licensePlateNo", e.target.value);
                        }}
                        />
                      </div>
                      <ErrorMessage name="car.licensePlateNo">
                        {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="flex-1">
                      <div className="input_field ">
                        <p className="text-gray font-16 font-500 mb-12">
                          Color
                        </p>
                        <Field
                        type="text"
                        placeholder="Black"
                        className= "input-field-styles"
                          name="car.color"
                          value={formik.values.car.color}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("car.color", e.target.value);
                          }}
                         
                          />  
                      </div>
                      <ErrorMessage name="car.color">
                        {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>

                {/* Cron job section: */}
                <div className="flex gap-10 align-items-center mt-24">
                  
                  <AccessTimeOutlinedIcon style={{fontSize: 20}} />
                  <p className="font-13 font-500 text-color-black">Setup Driver Events</p>
                  <ToolTipMessage
                                       type="primary"
                                       content="The events will be triggered based on the flight landing time if a flight number is provided.
                                            If no flight number is available, they will be triggered according to the pickup time."
                                     >
                                       <div className="">
                                         <div>
                                          <InfoOutlinedIcon style={{color: '#29AEE6', fontSize: 16, cursor: 'pointer'}} />
                                         </div>
                                       </div>
                                     </ToolTipMessage>
                </div>

                {/* Ride Section */}
                <div className="box-shadow-all-3 auto-driver-field-spacing-spacing mt-22">
                  <div className="flex align-items-center justify-content-between">
                    <MOSwitch
                      name="driverDepartedToAirportEvent.isActive"
                      isOnStatus={
                        formik.values.driverDepartedToAirportEvent.isActive
                      }
                      id={1}
                      setStatus={(switchValue: boolean) => {
                        formik.setFieldValue(`driverDepartedToAirportEvent`, {
                          ...formik.values.driverDepartedToAirportEvent,
                          isActive: switchValue,
                        });
                      }}
                      isParentControl={false}
                    />
                    <div>
                    <RemoveRedEyeOutlined 
                    style={{ cursor: "pointer", color: "#2CA9DD" }}
                    onClick={() => handleLocation('rideStart')}
                  />

                    </div>
                  </div>
                  <div className="flex gap-24 mt-16">
                    <div className="flex-1">
                      <div className="input_field">
                        <p className="text-primary font-16 font-500 mb-12">
                          Start Ride:
                        </p>
                        <Field
                          name="driverDepartedToAirportEvent.mins"
                          type="number"
                          min={0}
                          placeholder="10 Minutes"
                          className="input-field-styles"
                          style={{
                            borderColor: '#29AEE6',
                            borderWidth: '1.8px',
                            borderRadius: 5,
                            position: 'relative'
                          }}
                          value={formik.values.driverDepartedToAirportEvent.mins ?? ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value === '' ? null : Number(e.target.value);
                            formik.setFieldValue('driverDepartedToAirportEvent.mins', value);
                          }}
                        />
                      </div>
                      <ErrorMessage name="driverDepartedToAirportEvent.mins">
                      {(msg) => <div style={{ color: "red", margin: "5px" }}>{msg}</div>}
                    </ErrorMessage>
                    </div>
                    <div className="flex-1">
                      <div className="input_field">
                        <p
                          className="text-gray font-16 font-500 mb-12"
                          style={{
                            color: "#428702",  
                          }
                        }
                        >
                        Longitude
                        </p>
                        <Field
                          type="number"
                          name="driverDepartedToAirportEvent.longitude"
                          placeholder="435:378:4567"
                          className= "input-field-styles"
                          style={{
                            borderColor: "#428702",
                          }}
                          value={formik.values.driverDepartedToAirportEvent.longitude}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("driverDepartedToAirportEvent.longitude", e.target.value);
                          }}
                        />
                      </div>
                      <ErrorMessage name="driverDepartedToAirportEvent.latitude">
                      {(msg) => <div style={{ color: "red", margin: "5px" }}>{msg}</div>}
                    </ErrorMessage>
                    </div>
                    <div className="flex-1">
                      <div className="input_field">
                        <p
                          className="text-gray font-16 font-500 mb-12"
                          style={{
                            color: "#428702",
                          }}
                        >
                          Latitude
                        </p>
                        <Field
                          type="number"
                          name="driverDepartedToAirportEvent.latitude"
                          placeholder="435:378:4567"
                          className= "input-field-styles"
                          style={{
                            borderColor: "#428702",
                          }}
                          value={formik.values.driverDepartedToAirportEvent.latitude}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("driverDepartedToAirportEvent.latitude", e.target.value);
                          }}
                        />
                     <ErrorMessage name="driverDepartedToAirportEvent.longitude">
                      {(msg) => <div style={{ color: "red", margin: "5px" }}>{msg}</div>}
                    </ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Arrived */}

                <div className="box-shadow-all-3 auto-driver-field-spacing-spacing mt-22">
                  <div className="flex align-items-center justify-content-between">
                    <MOSwitch
                      isOnStatus={
                        formik.values.driverArrivedToAirportEvent.isActive
                      }
                      id={2}
                      setStatus={(switchValue: boolean) => {
                        formik.setFieldValue(`driverArrivedToAirportEvent`, {
                          ...formik.values.driverArrivedToAirportEvent,
                          isActive: switchValue,
                        });
                      }}
                      isParentControl={false}
                    />
                    <div>
                    <RemoveRedEyeOutlined 
                    style={{ cursor: "pointer",   color: "#2CA9DD" }}
                    onClick={() => handleLocation('arrived')}
                  />
                    </div>
                  </div>
                  <div className="flex gap-24 mt-16">
                    <div className="flex-1">
                      <div className="input_field">
                        <p className="text-success font-16 font-500 mb-12">
                          Arrived
                        </p>
                        <Field style={{ 
                          borderColor: '#428702',
                          borderWidth: "1.8px",
                          borderRadius: 5}}
                          type="number"
                          min={0}
                          name="driverArrivedToAirportEvent.mins"
                          className= "input-field-styles"
                          placeholder="15 Minutes"
                          value={formik.values.driverArrivedToAirportEvent.mins ?? ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value === '' ? null : Number(e.target.value);
                            formik.setFieldValue('driverArrivedToAirportEvent.mins', value);
                          }}
                        />
                       
                      </div>
                      <ErrorMessage name="driverArrivedToAirportEvent.mins" >
                        {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="flex-1">
                      <div className="input_field ">
                        <p className="text-gray font-16 font-500 mb-12">
                          Longitude
                        </p>
                        <Field
                          type="number"
                          name="driverArrivedToAirportEvent.longitude"
                          className= "input-field-styles"
                          placeholder="435:378:4567"
                          value={formik.values.driverArrivedToAirportEvent.longitude}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("driverArrivedToAirportEvent.longitude", e.target.value);
                          }}
                        />
                        <ErrorMessage name="driverArrivedToAirportEvent.longitude">
                          {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="input_field ">
                        <p className="text-gray font-16 font-500 mb-12">
                          Latitude
                        </p>
                        <Field
                          type="number"
                          name="driverArrivedToAirportEvent.latitude"
                          placeholder="435:378:4567"
                          className= "input-field-styles"
                          value={formik.values.driverArrivedToAirportEvent.latitude}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("driverArrivedToAirportEvent.latitude", e.target.value);
                          }}
                        />
                        <ErrorMessage name="driverArrivedToAirportEvent.latitude">
                          {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>

                {/* No Show: */}
                <div className="box-shadow-all-3 auto-driver-field-spacing-spacing mt-22">
                  <div className="flex align-items-center justify-content-between">
                    <MOSwitch
                      isOnStatus={
                        formik.values.driverNoShowToAirportEvent.isActive
                      }
                      id={3}
                      setStatus={(switchValue: boolean) => {
                        formik.setFieldValue(`driverNoShowToAirportEvent`, {
                          ...formik.values.driverNoShowToAirportEvent,
                          isActive: switchValue,
                        });
                      }}
                      isParentControl={false}
                    />
                    <div>
                    <RemoveRedEyeOutlined 
                    style={{ cursor: "pointer", color: "#2CA9DD" }}
                    onClick={() => handleLocation('noShow')}
                  />
                    </div>
                  </div>
                  <div className="flex  gap-24 mt-16">
                    <div className="flex-1">
                      <div className="input_field">
                        <p
                          className="text-gray font-16 font-500  mb-12"
                          style={{
                            color: "#FC0808",
                          }}
                        >
                          No Show:
                        </p>
                        <Field
                        name="driverNoShowToAirportEvent.mins"
                          type="number"
                          min={0}
                          className= "input-field-styles"
                          placeholder="10 Minutes"
                          style={{
                            borderColor: "#FC0808",
                            borderWidth: "1.5px",
                            borderRadius: 5, 
                          }}
                        value={formik.values.driverNoShowToAirportEvent.mins ?? ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let value = e.target.value === '' ? null : Number(e.target.value);
                            formik.setFieldValue('driverNoShowToAirportEvent.mins', value);
                          }}  
                        />
                      </div>
                        <ErrorMessage name="driverNoShowToAirportEvent.mins">
                          {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                        </ErrorMessage>
                    </div>
                    <div className="flex-1">
                      <div className="input_field ">
                        <p className="text-gray font-16 font-500 mb-12">
                          Longitude
                        </p>
                        <Field
                          type="number"
                          name="driverNoShowToAirportEvent.longitude"
                          placeholder="435:378:4567"
                          className= "input-field-styles"
                          value={formik.values.driverNoShowToAirportEvent.longitude}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("driverNoShowToAirportEvent.longitude", e.target.value);
                          }}
                        />
                        <ErrorMessage name="driverNoShowToAirportEvent.longitude">
                          {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="input_field ">
                        <p className="text-gray font-16 font-500 mb-12">
                          Latitude
                        </p>
                        <Field
                          type="number"
                          name="driverNoShowToAirportEvent.latitude"
                          placeholder="435:378:4567"
                          className= "input-field-styles"
                          value={formik.values.driverNoShowToAirportEvent.latitude}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("driverNoShowToAirportEvent.latitude", e.target.value);
                          }}
                        />
                        <ErrorMessage name="driverNoShowToAirportEvent.latitude">
                          {(msg) => <div style={{ color: "red", margin : "5px" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Add profile Image */}
                <div className="flex align-item-center gap-16 mt-16">
                  <Add style={{ height: "36px" , opacity: 0.8}} />
                  <p className="font-14 font-500">Add Profile Picture</p>
                </div>
                <FileUploaderDriver
                accept={'.png, .jpg, .jpeg'}
                label={'Upload Image'}
                callback={(files) => {
                  if (files && files.length > 0) {
                    formik.setFieldValue("driver.profileImage", files[0]);
                  }else {
                    formik.setFieldValue("driver.profileImage", null);
                  }
                }}
                style={{position: 'relative'}}
                profileImage={formik.values.driver.profileImage}
                />

                {/* save and cancel button */}
                <div className="Sm-screen flex align-items-center justify-content-end justify-content-md-start mt-24">
                  <div>
                    <MOButtonV2
                      type="submit"
                      text="SAVE AUTO DRIVER EVENTS"
                      textColor="#ffffff"
                      radius={4}
                      backgroundColor="#29AEE6"
                      height={40}
                      fontSize={11}
                      padding={"8.5px 63px"}
                      disabled={formik.isSubmitting}
                      loading= {formik.isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
      </div>
    </>
  );
};
interface mapStateProps {
  userInfo: {
    token: string;
  };
}
const mapState = (state: mapStateProps) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
