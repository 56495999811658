import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../../components/BreadCrumb";
import { useParams } from "react-router";
import DateRangeField from "../../../../components/commons/DateRangeField";
import Select from "react-select";
import SearchingInput from "../../../../components/commons/SearchingInput";
import EditIcon from "../../../../assets/icons/lucide_edit.svg";
import mobioApi from "../../../../services";
import { connect } from "react-redux";
import { formatCurrency } from "../../../../helpers/getSymbolFromCurrency";
import { parseJsonString } from "../../../../helpers/parseJson";
import Pagination from "../../../../components/commons/Pagination";
import { CircularProgress } from "@material-ui/core";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Sort,
} from "@material-ui/icons";
const SERVICE_LEVEL_ICONS = {
  LUXURY:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5045.png",
  LARGE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  EXECUTIVE:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5046.png",
  STANDARD:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5044.png",
  MINI_BUS:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  MINIBUS:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  EXECUTIVE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  ELECTRIC:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5047.png",
  ELECTRIC_STANDARD:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5047.png",
  CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  ELECTRIC_LUXURY:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273+(1).png",
  ELECTRIC_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  ELECTRIC_EXE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  ELECTRIC_LARGE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  EXE_CARRIER:
    "https://mobio-og-new.s3.eu-central-1.amazonaws.com/1732876067061",
  LARGE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
};
const dateRanges = [
  { value: "01-01-2024-07-01-2024", label: "01-01-2024 to 07-01-2024" },
  { value: "08-01-2024-14-01-2024", label: "08-01-2024 to 14-01-2024" },
  { value: "15-01-2024-21-01-2024", label: "15-01-2024 to 21-01-2024" },
  { value: "22-01-2024-28-01-2024", label: "22-01-2024 to 28-01-2024" },
  { value: "29-01-2024-04-02-2024", label: "29-01-2024 to 04-02-2024" },
  { value: "05-02-2024-11-02-2024", label: "05-02-2024 to 11-02-2024" },
  { value: "12-02-2024-18-02-2024", label: "12-02-2024 to 18-02-2024" },
  { value: "19-02-2024-25-02-2024", label: "19-02-2024 to 25-02-2024" },
];

// Define routes options
const routes = [
  { value: "PAK", label: "PAK" },
  { value: "UK", label: "UK" },
  { value: "AMS", label: "AMS" },
  { value: "NYC", label: "NYC" },
  { value: "LON", label: "LON" },
  { value: "DXB", label: "DXB" },
  { value: "BKK", label: "BKK" },
  { value: "SIN", label: "SIN" },
];
const SERVICE_LEVEL_OPTIONS = [
  { value: "ALL", label: "All" },
  { value: "LUXURY", label: "Luxury" },
  { value: "LARGE_PEOPLE_CARRIER", label: "Large People Carrier" },
  { value: "EXECUTIVE", label: "Executive" },
  { value: "STANDARD", label: "Standard" },
  { value: "MINIBUS", label: "Minibus" },
  { value: "EXECUTIVE_PEOPLE_CARRIER", label: "Executive People Carrier" },
  { value: "ELECTRIC", label: "Electric" },
  { value: "ELECTRIC_STANDARD", label: "Electric Standard" },
  { value: "CARRIER", label: "Carrier" },
  { value: "ELECTRIC_LUXURY", label: "Electric Luxury" },
  { value: "ELECTRIC_PEOPLE_CARRIER", label: "Electric People Carrier" },
  {
    value: "ELECTRIC_EXE_PEOPLE_CARRIER",
    label: "Electric Executive People Carrier",
  },
  {
    value: "ELECTRIC_LARGE_PEOPLE_CARRIER",
    label: "Electric Large People Carrier",
  },
  { value: "EXE_CARRIER", label: "Executive Carrier" },
  { value: "LARGE_CARRIER", label: "Large Carrier" },
  { value: "PEOPLE_CARRIER", label: "People Carrier" },
];

const RequestAnalysisPerAddressDetail = ({ token }) => {
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
    origin,
    destination,
  } = useParams();

  const [requestedData, setRequestedData] = useState([]);
  const [requestedDataBK, setRequestedDataBK] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectServiceLevel, setSelectServiceLevel] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const weekFormatOptionLabel = (name, { context }) => (
    <>
      {context === "value" && (
        <p className="m-0 ">
          <span className="font-600 ">This week </span>
          <span className="font-12 font-400"> {`(From ${name.label})`}</span>
        </p>
      )}

      {context === "menu" && (
        <>
          <p className="font-14 mb-0">{name.label}</p>
        </>
      )}
    </>
  );
  const routeFormatOptionLabel = (name, { context }) => (
    <>
      {context === "value" && (
        <p className="m-0 ">
          <span className="font-400 font-400 ">Most Searched Routes: </span>
          <span className="font-14 font-600"> {name.label}</span>
        </p>
      )}

      {context === "menu" && (
        <>
          <p className="font-14 mb-0">{name.label}</p>
        </>
      )}
    </>
  );
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #ced4da" : "1px solid #ced4da",
      boxShadow: state.isFocused ? "0 0 0 1px #ced4da" : provided.boxShadow,
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "1px 6px",
      backgroundColor: state.isSelected ? "#29AEE6" : "#fff",
      color: state.isSelected ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: state.isSelected ? "#0056b3" : "#e9ecef",
        color: state.isSelected ? "#fff" : "#000",
      },
    }),
    // singleValue: (provided) => ({
    //   ...provided,
    //   fontSize: "16px",
    //   color: "#495057",
    // }),
  };

  // const getRequestAnalysis = async (serviceLevel=null) => {
  //   try {
  //     const api = mobioApi(token);
  //     const response = await api.getRequestedDataAnalysis(
  //       airportId,
  //       origin,
  //       destination,
  //       serviceLevel
  //     );

  //     setRequestedData(response?.data?.result);
  //     setRequestedDataBK(response?.data?.result);
  //   } catch (error) {
  //     setRequestedData([]);
  //     setRequestedDataBK([]);
  //   }
  // };

  const getRequestAnalysis = useCallback(
    async (page = 1, limit = 10, serviceLevel = null) => {
      try {
        setLoading(true);
        const api = mobioApi(token);
        const response = await api.getRequestedDataAnalysis(
          airportId,
          origin,
          destination,
          selectServiceLevel,
          page,
          limit,
          sortingOrder,
          integrationType
        );

        const result = response?.data?.result || [];
        const total = response?.data?.pagination?.totalCount || 0;

        return { items: result, total };
      } catch (error) {
        return { items: [], total: 0 };
      } finally {
        setLoading(false);
      }
    },
    [
      airportId,
      token,
      origin,
      destination,
      selectServiceLevel,
      sortingOrder,
      integrationType,
    ]
  );

  const handlePageChange = useCallback((items) => {
    setRequestedData(items);
  }, []);

  // useEffect(() => {
  //   if (airportId && origin && destination) {
  //     getRequestAnalysis();
  //   }
  // }, [airportId, origin, destination]);

  const paths = [
    {
      name: "Analysis & Rate Automation ",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
  ];
  const secondaryPaths = [
    {
      name: "Netherlands",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },
    {
      name: "Manage AMS ",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
    {
      name: "Analysis & Rate Automation",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/analysis-list`,
    },
    {
      name: "Request Analysis Detail",
      url: ``,
    },
  ];
  return (
    <div>
      <div className="mt-18">
        <div>
          <Breadcrumb paths={paths} />
          <div className="breadcrumb-description">
            Report about the Analysis and automation
          </div>
          <div className="mt-12 ml-6">
            <Breadcrumb paths={secondaryPaths} secondary={true} />
          </div>
        </div>
      </div>
      <div>
        <div>
          {/* tables area */}
          <div className="">
            {/* Searching Area */}
            <div className="mt-16">
              <div>
                <p className="m-0 font-14 font-400 mb-8">Service Level</p>
                <div className="flex justify-content-between align-item-center width-full">
                  <div className="flex gap-10">
                    {/* <div>
                      <DateRangeField
                        dateValue={new Date().toString()}
                        mode="range"
                      />
                    </div> */}
                    {/* <div className="w-341">
                      <Select
                        options={dateRanges}
                        placeholder="Select date range"
                        formatOptionLabel={weekFormatOptionLabel}
                        styles={customStyles}
                        isSearchable={false}
                      />
                    </div> */}
                    <div className="w-256">
                      <Select
                        options={SERVICE_LEVEL_OPTIONS}
                        placeholder="Select the Service Level"
                        // formatOptionLabel={routeFormatOptionLabel}
                        styles={customStyles}
                        isSearchable={false}
                        onChange={async (val) => {
                          if (val?.value && val?.value !== "ALL") {
                            setSelectServiceLevel(val?.value);
                            setCurrentPage(1);
                            // await getRequestAnalysis(1, 10, val?.value);
                            // const slFil = requestedDataBK?.filter(
                            //   (d) =>
                            //     parseJsonString(d?.response)
                            //       ?.transportCategory === val?.value
                            // );
                            // setRequestedData(slFil);
                          } else {
                            // await getRequestAnalysis(1, 10, null);
                            setSelectServiceLevel(null);
                            setCurrentPage(1);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* <div
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      src={EditIcon}
                      alt="Edit"
                      style={{
                        width: "27px",
                        height: "27px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div>
                    <SearchingInput className="border-solid-gray " />
                  </div> */}
                </div>
              </div>
            </div>
            {/* table */}
            <div className=" mt-30">
              <table className="custom-table-v3">
                <thead>
                  <tr>
                    <th
                      className="cursor-pointer"
                      onClick={() => {
                        if (!loading) {
                          if (sortingOrder && sortingOrder === "asc") {
                            setSortingOrder("desc");
                          } else {
                            setSortingOrder("asc");
                          }
                          setCurrentPage(1);
                        }
                      }}
                    >
                      <div className="flex align-item-center sorting-hover">
                        <p className="text-no-warp"> Search Requests</p>
                        <div style={{ marginLeft: "4px" }}>
                          {/* <Sort /> */}
                          {sortingOrder ? (
                            sortingOrder === "desc" ? (
                              <KeyboardArrowDown style={{ marginTop: "2px" }} />
                            ) : (
                              <KeyboardArrowUp style={{ marginTop: "2px" }} />
                            )
                          ) : (
                            <Sort  style={{ marginTop: "2px" }}/>
                          )}
                        </div>
                      </div>
                    </th>
                    <th className="">Conversion </th>
                    <th className="" style={{ padding: "0px 20px" }}>
                      Pickup Address
                    </th>
                    <th className="" style={{ padding: "0px 20px" }}>
                      Drop-off Address
                    </th>
                    <th className="" style={{ padding: "0px 20px" }}>
                      Distance{" "}
                    </th>
                    <th className="" style={{ padding: "0px 20px" }}>
                      Passenger
                    </th>
                    
                    <th
                      className="text-no-warp"
                      style={{ padding: "0px 20px" }}
                    >
                      Set Rate
                    </th>
                    <th className="" style={{ padding: "0px 20px" }}>
                      Service Level
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <tr>
                    <td
                      colSpan="7"
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      <CircularProgress size="30px" />
                    </td>
                  </tr>
                ) : (
                  <>
                    <tbody>
                      {requestedData?.length>0 ? requestedData?.map((row, index) => (
                        <tr
                          key={index}
                          style={{ color: row?.notes ? "red" : "" }}
                        >
                          <td>{row?.count}</td>
                          <td>{row?.conversion}</td>

                          <td style={{ width: "200px", padding: "0px 20px" }}>
                            {row?.origin}
                          </td>
                          <td style={{ width: "200px", padding: "0px 20px" }}>
                            {row?.destination}
                          </td>
                          <td style={{ padding: "0px 20px" }}>
                            {row.distance} KM
                          </td>
                          <td style={{ padding: "0px 20px" }}>
                            {row?.passenger || ""}
                          </td>
                        

                          <td style={{ padding: "0px 20px" }}>
                            {row?.rate
                              ? formatCurrency(
                                  parseJsonString(row?.response)
                                    ?.transportCategory?.price?.currency,
                                  row?.rate
                                )
                              : "NULL"}
                            {/* {formatCurrency(
                          parseJsonString(row?.response)?.transportCategory
                            ?.price?.currency,
                          parseJsonString(
                            row?.response
                          )?.price?.salePriceMin.toFixed(2)
                        )}
                        {" - "}
                        {parseJsonString(
                          row?.response
                        )?.price?.salePriceMax?.toFixed(2)} */}
                          </td>
                          <td style={{ padding: "0px 20px" }}>
                            {/* {parseJsonString(row?.response)?.transportCategory} */}

                            <div className="flex align-item-center">
                              {parseJsonString(row?.response)
                                ?.transportCategory || row?.serviceLevel ? (
                                <>
                                  <img
                                    src={
                                      SERVICE_LEVEL_ICONS[
                                        row?.serviceLevel
                                          ? row?.serviceLevel
                                          : parseJsonString(row?.response)
                                              ?.transportCategory
                                      ]
                                    }
                                    alt="icon"
                                    className="distance-rate-table-icon"
                                  />
                                  <p
                                    className="m-0"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {" "}
                                    {row?.serviceLevel
                                      ? row?.serviceLevel
                                          ?.replaceAll("_", " ")
                                          .toLowerCase()
                                      : parseJsonString(row?.response)
                                          ?.transportCategory?.replaceAll(
                                            "_",
                                            " "
                                          )
                                          .toLowerCase()}
                                  </p>
                                </>
                              ) : (
                                "NULL"
                              )}
                            </div>
                          </td>
                        </tr>
                      )):<>
                       <tr>
                  <td
                    colSpan="8"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <p>Record not found.</p>
                  </td>
                </tr>
                      </>}
                    </tbody>
                  </>
                )}
              </table>
            </div>

            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                fetchData={getRequestAnalysis}
                itemsPerPage={10}
                onPageChange={handlePageChange}
                currentPage={currentPage} // Pass currentPage state
                onCurrentPageChange={(page) => setCurrentPage(page)} // Update currentPage state
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(RequestAnalysisPerAddressDetail);
