import { useState, forwardRef } from 'react';
import { CheckCircle, Edit } from "@mui/icons-material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import moment from "moment";
import { EventTextDetails } from "../../../../enum";


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const showGooglePlace = (lat, lng) => {
  const origin = encodeURIComponent(lat+","+lng);
  const link = `https://www.google.com/maps/place/${origin}`;
  window.open(link,"_blank");
}

export default function TrackingEventDialogue({ dialogueOpen, setDialogueOpen, events,bookingDetail}) {
 console.log("BOOKING DATA",bookingDetail)
  return (
    <div>
      <Dialog
        open={dialogueOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>setDialogueOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          maxHeight: "90vh", 
          overflow: "hidden", 
        }}
      >
        <DialogTitle>Driver Tracking Events</DialogTitle>
        <DialogContent
           dividers
           sx={{
             maxHeight: "70vh", 
             overflowY: "auto", 
            }}
          >
     
        {
            events.map((trackingEvent, index) => <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                    paddingLeft: 7,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: 4,
                      marginTop: 16,
                      backgroundColor: "black",
                    }}
                  ></div>
                  <div style={{ marginLeft: -11 }}>
                    <CheckCircle
                      style={{
                        color: "green",
                        width: 20,
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer"
                  }}
                  onClick={()=>{showGooglePlace(trackingEvent.lat, trackingEvent.long)}}
                  
                >
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      padding: 0,
                      marginBottom: 5,
                      marginTop: 8,
                    }}
                  >
                    {EventTextDetails[trackingEvent.eventName]}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 14,
                        color: "black",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {moment(trackingEvent.createdAt).format(
                        "DD MMM YYYY HH:mm:ss"
                      )}{" "}
                    </p>
                    <div
                      style={{
                        width: 5,
                        height: 5,
                        backgroundColor: "black",
                        borderRadius: 8,
                        marginRight: 5,
                        marginLeft: 5,
                        marginTop: 0,
                      }}
                    ></div>
                    <p
                      style={{
                        fontSize: 14,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {trackingEvent.lat}, {trackingEvent.long},{" "}
                      </p>
                      <p
                       style={{
                        fontSize: 14,
                        padding: 0,
                        marginLeft: 5,
                        }}>
                        {trackingEvent?.user?.displayName}</p>
                  </div>
                </div>
              </div>)
              }
              {bookingDetail?.cancellationReason && 
              <div style={{marginTop:"20px"}}>
           <p
  style={{
    fontSize: 16,
    fontWeight: "bold",
    padding: 0,
    marginBottom: 5,
    marginTop: 8,
  }}
>
  Passenger Number:{" "}
  <span
    style={{
      fontSize: 14,
      fontWeight: "normal",
    }}
  >
    {bookingDetail?.guestPhoneNumber !== null && bookingDetail?.guestPhoneNumber !== undefined
      ? bookingDetail?.guestPhoneNumber
      : "Not Available"}
  </span>
</p>

              <p
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      padding: 0,
                      marginBottom: 5,
                      marginTop: 8,
                    }}
                  >
                    Flight Time: <span style={{
                      fontSize: 14,
                      fontWeight: "normal",
                    }}> {bookingDetail?.flightLandTime ?moment(bookingDetail?.flightLandTime).format(
                        "DD MMM YYYY HH:mm:ss"
                      ):"NULL"}</span>
                  </p>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      padding: 0,
                      marginBottom: 5,
                      marginTop: 8,
                    }}
                  >
                    Booking Time: <span style={{
                      fontSize: 14,
                      fontWeight: "normal",
                    }}> {moment(bookingDetail?.dateTime).format(
                        "DD MMM YYYY HH:mm:ss"
                      )}</span>
                  </p>
              <p
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      padding: 0,
                      marginBottom: 5,
                      marginTop: 8,
                    }}
                  >
                    Reason: <span style={{
                      fontSize: 14,
                      fontWeight: "normal",
                    }}>{bookingDetail?.cancellationReason?.reason}</span>
                  </p>

                  {/* Image */}
                  <div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
                  {bookingDetail?.cancellationReason?.picture?.map((item)=>{
                    return<>
                      <div style={{marginRight:"12px"}}>
                      <img src={item} style={{
                        width:"170px",
                        height:"180px",
                        objectFit:"contain"
                      }}/>
                      </div>
                    </>
                  })}
                  </div>
                

              </div>
              }
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setDialogueOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}