import React from "react";
import Button from "@material-ui/core/Button";

function ImageUploadButton({
  name,
  backgroundColor = "#2AABD5", // default background color
  color = "FFFFFF",
  fontSize = 16,
  border = "none",             // default border style
  borderRadius = 6,                // default font size in pixels
  ...rest
}) {
  return (
    <Button
      style={{
        borderRadius: borderRadius,
        border: border,
        backgroundColor,
        color,
        height: 45,
        width: 100,
        marginLeft: 10,
        marginTop: 16,
        fontSize, // using the fontSize prop
      }}
      variant="contained"
    
      {...rest}
    >
      {name}
    </Button>
  );
}

export default ImageUploadButton;
