import React, { useEffect, useRef, useState } from "react";
import { Calendar, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format as formatDate } from "date-fns";
import InputField from "./InputField";
import { CalendarMonth } from "@mui/icons-material";

interface DateRangeFieldProps {
  inputClassName?: string;
  name?: string;
  onChange?: (value: string) => void;
  error?: string;
  inputContainerClassName?: string;
  mode?: "calendar" | "datetime" | "range";
  dateValue: string;
  timeValue?: string;
}

const generateOptions = (limit: number): string[] =>
  Array.from({ length: limit }, (_, i) => i.toString().padStart(2, "0"));

const DateRangeField: React.FC<DateRangeFieldProps> = ({
  name,
  error,
  onChange,
  mode = "datetime",
  dateValue,
  timeValue,
  inputClassName = "text-[13px]",
  inputContainerClassName = "w-full h-[40px] ",
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [hours, setHours] = useState<string>("00");
  const [minutes, setMinutes] = useState<string>("00");
  const [isOpen, setIsOpen] = useState(false);
  const dateTimeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dateValue) {
      setDate(new Date(dateValue));
    }
    if (timeValue && mode === "datetime") {
      const [parsedHours, parsedMinutes] = timeValue.split(":");
      setHours(parsedHours || "00");
      setMinutes(parsedMinutes?.split(":")[0] || "00");
    }
  }, [dateValue, timeValue]);

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    if (newDate) {
      const formattedDateTime = `${formatDate(
        newDate,
        "yyyy-MM-dd"
      )}, ${hours}:${minutes}`;
      if (onChange) onChange(formattedDateTime);
      setIsOpen(false);
    }
  };

  // const handleRangeChange = (ranges: any) => {
  //   const { selection } = ranges;
  //   setRange(selection);
  //   const formattedRange = `${formatDate(
  //     selection.startDate,
  //     "yyyy-MM-dd"
  //   )} - ${formatDate(selection.endDate, "yyyy-MM-dd")}`;
  //   if (onChange) onChange(formattedRange);
  //   // setIsOpen(false);
  // };

  const handleRangeChange = (ranges: any) => {
    const { selection } = ranges;
    setRange(selection);

    // Only close the picker if both startDate and endDate are selected
    if (
      selection.startDate &&
      selection.endDate &&
      selection.startDate !== selection.endDate
    ) {
      const formattedRange = `${formatDate(
        selection.startDate,
        "yyyy-MM-dd"
      )} - ${formatDate(selection.endDate, "yyyy-MM-dd")}`;

      if (onChange) onChange(formattedRange);
      setIsOpen(false); // Close the date picker
    }
  };

  const handleInputClick = () => {
    setIsOpen((prev) => !prev);
  };

  const formattedValue =
    mode === "range"
      ? `${formatDate(range.startDate, "yyyy-MM-dd")} - ${formatDate(
          range.endDate,
          "yyyy-MM-dd"
        )}`
      : mode === "datetime"
      ? date
        ? `${formatDate(date, "yyyy-MM-dd")}, ${hours}:${minutes}`
        : ""
      : date
      ? `${formatDate(date, "yyyy-MM-dd")}`
      : "";

  return (
    <div ref={dateTimeRef} className="date-time-field-css" style={{
      position:"relative"
    }}>
      <InputField
        value={formattedValue}
        name={name}
        type="text"
        className={inputContainerClassName}
        inputClassName={inputClassName}
        error={error}
        readOnly
        onClick={handleInputClick}
        startAdornment={
          <CalendarMonth className="size-[22px] text-black" />
        }
      />
      {isOpen && (
        <div className="date-range-container">
          {mode === "range" ? (
            <DateRange
              rangeColors={["#2CA9DD"]}
              showMonthAndYearPickers={false}
              ranges={[range]}
              onChange={handleRangeChange}
              months={2}
              direction="horizontal"
              color="#2CA9DD"
              dayContentRenderer={(currentDate) => {
                const dayOfWeek = currentDate.getDay();
                const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

                // Check if the current date is within the selected range
                const isInRange =
                  range.startDate &&
                  range.endDate &&
                  currentDate >= range.startDate &&
                  currentDate <= range.endDate;

                // Check if the current date is the start or end of the range
                const isSelected =
                  (range.startDate &&
                    formatDate(currentDate, "yyyy-MM-dd") ===
                      formatDate(range.startDate, "yyyy-MM-dd")) ||
                  (range.endDate &&
                    formatDate(currentDate, "yyyy-MM-dd") ===
                      formatDate(range.endDate, "yyyy-MM-dd"));
                return (
                  <span
                    style={{
                      // color: isWeekend ? "#2CA9DD" : "black",
                      color:
                        isSelected || isInRange
                          ? "white"
                          : isWeekend
                          ? "#2CA9DD"
                          : "black",
                      display: "inline-block",
                      width: "2rem",
                      height: "2rem",
                      lineHeight: "2rem",
                      textAlign: "center",
                    }}
                  >
                    {currentDate.getDate()}
                  </span>
                );
              }}
            />
          ) : (
            <></>
            // <Calendar
            //   date={date || new Date()}
            //   onChange={(newDate) => handleDateChange(newDate)}
            //   showMonthAndYearPickers={false}
            //   color="#2CA9DD"
            // />
          )}
          {mode === "datetime" && (
            <div className="mt-4 flex items-center justify-between gap-2 py-4 px-7 bg-[#F8F8F8] rounded-ee-lg rounded-es-lg">
              <p className="text-sm font-normal text-[#323232]">Time Picker</p>
              <div className="self-end ml-auto">
                <select
                  id="hours"
                  className="border border-black/30 cursor-pointer px-1 font-normal text-sm text-[#323232] rounded outline-none bg-transparent"
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                >
                  {generateOptions(24).map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  id="minutes"
                  className="border border-black/30 cursor-pointer px-1 font-normal text-sm text-[#323232] rounded outline-none bg-transparent"
                  value={minutes}
                  onChange={(e) => setMinutes(e.target.value)}
                >
                  {generateOptions(60).map((minute) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DateRangeField;
