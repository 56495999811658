import React, { ChangeEventHandler, ReactNode } from "react";


interface InputFieldProps {
  value?: string | number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  multiline?: boolean;
  type?: string;
  name?: string;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
  label?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  rounded?: "rounded-none" | "rounded" | "rounded-md " | "rounded-sm";
  labelClassName?: string;
  disabled?: boolean;
  rows?: number;
  [key: string]: any;

}

const InputField: React.FC<InputFieldProps> = ({
  value,
  onChange,
  placeholder,
  className = "",
  inputClassName = "",
  multiline = false,
  type = "text",
  name,
  onBlur,
  error,
  label,
  startAdornment = <></>,
  endAdornment = <></>,
  rounded = "rounded-sm",
  labelClassName = "font-normal text-[17px] pb-3.5 text-black ",
  disabled = false,
  rows = 3,
  tooltipStyle = { width: "187px" },
  tooltipPlacement = "top-start",
  tooltipContent,

  ...rest
}) => {
  return (
    <>
  <div className="input-field-wrapper">
  {label && (
    <label className="input-field-label" htmlFor={name}>
      {label}
  
    </label>
  )}
  <div className={`input-field-container ${rounded} ${disabled ? "disabled" : ""} ${multiline ? "multiline" : ""} ${className}`}>
    {multiline ? (
      <textarea className={`input-field-textarea ${inputClassName}`} value={value} onChange={onChange} placeholder={placeholder} rows={rows} name={name} onBlur={onBlur} />
    ) : (
      <>
        {startAdornment}
        <input className={`input-field-input ${inputClassName}`} value={value} onChange={onChange} placeholder={placeholder} type={type} name={name} onBlur={onBlur} disabled={disabled} {...rest} />
        {endAdornment}
      </>
    )}
  </div>
  {error && <p>{error}</p>}
</div>

    </>
  );
};

export default InputField;
