 const NLbanksBIC = [
    { name: "ABN AMRO", iban: "NL91 ABNA 0417 1643 00", bic: "ABNANL2A", code: "ABNA" },
    { name: "Rabobank", iban: "NL91 RABO 0312 3456 78", bic: "RABONL2U", code: "RABO" },
    { name: "ING Bank", iban: "NL91 INGB 0001 2345 67", bic: "INGBNL2A", code: "INGB" },
    { name: "SNS Bank", iban: "NL91 SNSB 0123 4567 89", bic: "SNSBNL2A", code: "SNSB" },
    { name: "ASN Bank", iban: "NL91 ASNB 0123 4567 89", bic: "ASNBNL21", code: "ASNB" },
    { name: "RegioBank", iban: "NL91 RBRB 0123 4567 89", bic: "RBRBNL21", code: "RBRB" },
    { name: "Triodos Bank", iban: "NL91 TRIO 0123 4567 89", bic: "TRIONL2U", code: "TRIO" },
    { name: "Knab", iban: "NL91 KNAB 0123 4567 89", bic: "KNABNL2H", code: "KNAB" },
    { name: "bunq", iban: "NL91 BUNQ 0123 4567 89", bic: "BUNQNL2A", code: "BUNQ" },
    { name: "Van Lanschot Bankiers", iban: "NL91 FVLB 0123 4567 89", bic: "FVLBNL22", code: "FVLB" },
    { name: "Handelsbanken", iban: "NL91 HAND 0123 4567 89", bic: "HANDNL2A", code: "HAND" },
    { name: "NIBC Bank", iban: "NL91 NIBB 0123 4567 89", bic: "NIBBNL2G", code: "NIBB" },
    { name: "Deutsche Bank Nederland", iban: "NL91 DEUT 0123 4567 89", bic: "DEUTNL2A", code: "DEUT" }
  ];

  export function getBICFromNLIBAN(iban) {
    const bankCode = iban.slice(4, 8).toLowerCase();
    const bank = NLbanksBIC.find(b => b.code.toLowerCase() === bankCode);
    return bank ? bank.bic : "";
  }
  